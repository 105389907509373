import React, { useEffect, useState} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import { useFirebase } from '../../core/context/firebase-context';

//Formik
import { Formik, Form} from 'formik';
import * as Yup from "yup";


//Material
import { Grid, Typography } from "@material-ui/core";

//Core components
import AppPopup from '../../core/components/Popup/AppPopup';
import AppButton from '../../core/components/Button/AppButton';
import AppPhone from '../../core/components/Input/Phone/AppPhone';
import AppList  from '../../core/components/Input/List/AppList';
import AppSnackbar from '../../core/components/Snackbar/AppSnackbar';
import AppFormError from '../../core/components/FormError/AppFormError';
import AppButtonGroup from '../../core/components/ButtonGroup/AppButtonGroup';
import AppText from '../../core/components/Input/Text/AppText';

//Popups
import PopupAssignAccount from './PopupAssignAccount';
import PopupAccountSelected from './PopupAccountSelected';
import AppPassword from '../../core/components/Input/Password/AppPasword';

/**
 * A component for creating a new user and assigning accounts.
 *
 * @param {object} props - Component props.
 * @param {Function} props.handleClose - Function to handle closing the popup.
 * @param {boolean} props.popupOpen - Whether the popup is open.
 * @param {Function} props.getAccountWithId - Function to get an account with its ID.
 * @param {Function} props.handleRefresh - Function to refresh data.
 */

function PopupNewUser(props) {
    //Backend
    const firebase = useFirebase();


    const [defaultAccount, setDefaultAccount] = useState({});
    const [popupOpenAssign, setOpenPopupAssign] = useState(false);
    const [open, setOpen]= useState(false);
    const[accounts, setAccounts] = useState([]);
    const [filteredOrganizations, setFilteredOrganizations] = useState({});

    const[organizations, setOrganizations] = useState({});
    const[isAccount, setIsAccount] = useState(false);
    const[newAccount, setNewAccount] = useState([]);
  

    const[successUser,setSuccess]=useState(false);
 

    const[errorOK,setError]=useState(false);
    const[errorMessage,setErrorMessage]=useState('');
    const[accountsToEliminate, setAccountsToEliminate]=useState([]);
    const[errorAccounts, setErrorAcounts] = useState(false);
    const [accountSelected, setAccountSelected] = useState({});
    const[openAccountSelected, setOpenPopupAccountSelected] = useState(false);

    const[user, setUser] = useState({});
    const[phone, setPhone] = useState('');

     /**
     * Retrieves all organizations from Firebase.
     */
    const getAllOrganizations = async() =>{
      await firebase
      .getOrganizations()
      .then(function (result) {
        setOrganizations(result);
        filterOrganizations(result);
        return result;
      })
      .catch(function (error) {
        throw new Error(error);
             
      });
    };

    useEffect(()=>{
      if(!open){
        setUser({});
        setAccounts([]);
        setDefaultAccount([]);
        setErrorMessage('');
        setPhone('');
      }
      
    },[open]);

    /**
   * Filters organizations to get the ones that have accounts.
   *
   * @param {object} orgs - All organizations.
   */
    const filterOrganizations = (orgs)=>{
      let filtered = [];
      if(orgs !== undefined){
        Object.keys(orgs).map(key => {
         if(orgs[key].cuentas != undefined){
           filtered.push(orgs[key]);
         }
       });      
       setFilteredOrganizations(filtered);
      }
        
     
     };


    useEffect(() => {
      if(Object.keys(defaultAccount)!=0){
        setIsAccount(true);
      }
    }, [defaultAccount]);

    const handlePopupAccountClose = ()=>{
      setOpenPopupAssign(!popupOpenAssign);
      
    };

  
    /**
     * Handles opening the assign account popup and retrieves organizations.
     */
    const handlePopupAccount = async() =>{   
      const orgs = await getAllOrganizations();
      setOpenPopupAssign(!popupOpenAssign);     
    
    };

    const handlePopupCancel = () =>{
      setOpenPopupAccountSelected(false);
    };

     /**
     * Handles the popup for a selected account.
     *
     * @param {object} account - The selected account.
     */
     const handlePopupAccountSelected = async(account) =>{
      const result = await props.getAccountWithId(account.id);
      let perfiles = [];
      Object.keys(result.perfiles).map(key=>{
        if(result.perfiles[key].id !== 'inactivo'){
          perfiles.push(result.perfiles[key]);
        }
      });
      
      if(result!== undefined && account){       
        setAccountSelected({
          id: account.id,
          idElement: account.id,
          nombre: account.headerText,
          IdPerfilUsuario: account.perfil,
          perfiles: perfiles,
          admin_cuenta: account.admin_cuenta,
          base_datos: account.base_datos,
          nodo_raiz: account.nodo_raiz?account.nodo_raiz:null,
          superset_activo: account.superset_activo,
          superset_url: account.superset_url
        });
        setOpenPopupAccountSelected(true);
      }
  };

    useEffect(() => {
      setOpen(props.popupOpen);
    }, [props]);

    useEffect(()=>{
      if(errorOK){
        setErrorAcounts(false);
      }
    },[errorOK]);

    /**
   * Adds a user to the selected accounts.
   *
   * @param {string} uid - The user's unique identifier.
   */
    
    const userToAccounts = async(uid) =>{
      if(uid !== undefined || null){
        let data={};
        for(let acct=0; acct<accounts.length; acct++){
          if(accounts[acct].id !== defaultAccount.accountId){
            data={
              uid: uid,
              perfil: accounts[acct].perfilName,
              admin_cuenta: accounts[acct].admin_cuenta.toString(),
              base_datos: accounts[acct].base_datos.toString(),
              nodo_raiz: accounts[acct].nodo_raiz?accounts[acct].nodo_raiz.toString():null,
              superset_activo: accounts[acct].superset_activo?accounts[acct].superset_activo:false,
              superset_url: accounts[acct].superset_url?accounts[acct].superset_url:null
            };
              try{
                await firebase
                .addUserToAccount(data)
                .then(function (result) {
                    setUser({});
                    setAccounts([]);
                    setDefaultAccount([]);
                    setErrorMessage('');
                    setPhone('');          
                  
                });
              }catch(error){
                setErrorAcounts(true); 
                throw new Error(error);        
              } 
          }  
          
        }
      }

      
    };

  /**
   * Retrieves the user's unique identifier based on their email.
   *
   * @param {string} email - The user's email.
   */
   const IdNewUser = async (email)=>{
    await firebase
    .findUserByEmail(email)
    .then(function (result) {
      setUser({...user, 'uid': result.uid});
      userToAccounts(result.uid);
      
     })
    .catch(function (error) {
      throw new Error(error);
    });
    
   };
   
    const addNewAccount = (info) =>{

      if(info.defaultAccount && accounts.length ===0){
          setDefaultAccount(info);
          setNewAccount(info);
          setUser({...user,'defaultAccount': info.accountId});          
      }else if (accounts.find(account => info.accountId === account.id) !== undefined){
        if(info.defaultAccount){
          setDefaultAccount(info);
          setUser({...user,'defaultAccount': info.accountId});
          accounts.map(cuenta =>{
            if(cuenta.id === info.accountId){
              cuenta.admin_cuenta= info.admin_cuenta;
              cuenta.perfil = info.perfil;
              cuenta.perfilName = info.perfilName;
              cuenta.superset_activo= info.superset_activo?info.superset_activo:false,
              cuenta.superset_url= info.superset_url?info.superset_url:null;
            }
          }); 
        }else{
          accounts.map(cuenta =>{
            if(cuenta.id === info.accountId){
              cuenta.admin_cuenta= info.admin_cuenta;
              cuenta.perfil = info.perfil;
              cuenta.perfilName = info.perfilName;
              cuenta.superset_activo= info.superset_activo?info.superset_activo:false,
              cuenta.superset_url= info.superset_url?info.superset_url:null;
            }
          }); 
        }
              
      }else if(info.defaultAccount && accounts.find(account => info.accountId === account.id) === undefined){
        setDefaultAccount(info);
        setNewAccount(info);
        setUser({...user,'defaultAccount': info.accountId});  
      }else{           
        setNewAccount(info);             
      }
    };

    const formatAccount = async() => {  
      
      const accountId = newAccount.accountId;
      let nombreCuenta = '';

      const account = await firebase
      .getAccountById({accId:accountId})
      .then(function (result) {
        nombreCuenta = result.data.nombre;
        return result.data;
      })
      .catch(function (error) {
        throw new Error(error);
            
      });
      
      let cuenta=[...accounts];
      cuenta.push({
        headerText: nombreCuenta, 
        bodyText: ['Estado', newAccount.perfil != 'inactivo'?'activo':'inactivo'], 
        id:  newAccount.accountId, 
        idElement: newAccount.accountId,
        admin_cuenta: newAccount.admin_cuenta,
        perfilName: newAccount.perfilName,
        perfil: newAccount.perfil,
        base_datos: newAccount.base_datos,
        nodo_raiz: newAccount.nodo_raiz?newAccount.nodo_raiz:null,
        superset_activo: newAccount.superset_activo?newAccount.superset_activo:false,
        superset_url: newAccount.superset_url?newAccount.superset_url:null
      });
      setAccounts(cuenta);
    };

    const updateAccountSelected =  (info) =>{
      let cuentas = [...accounts];
      Object.keys(cuentas).map(key =>{
        if(cuentas[key].idElement === info.accountId){
          cuentas[key].admin_cuenta = info.admin_cuenta;
          cuentas[key].perfil = info.perfil;
          cuentas[key].perfilName = info.perfilName;
          cuentas[key].superset_activo= info.superset_activo?info.superset_activo:false;
          cuentas[key].superset_url= info.superset_url?info.superset_url:null;
        }
      });
      if(info.defaultAccount){
        setDefaultAccount(info);
        setUser({...user,'defaultAccount': info.accountId});
      }
      setAccounts(cuentas);
      setOpenPopupAccountSelected(false);
    };

    useEffect( () =>{
      if(Object.keys(newAccount)!=0){
        formatAccount(newAccount);
      }
      
    },[newAccount]);

    const handleChange = event => {
      console.log('');
    };
   

    const handleAlert = (type) =>{
      if(type=== 'success'){
        setSuccess(!successUser);
      }else if(type=== 'error'){
        setError(!errorOK);       
      }else if(type === 'errorAccounts'){
        setErrorAcounts(!errorAccounts);
      }      
    };

   
    return(
      <div>
         <ErrorBoundary> 
             <AppPopup title='Nuevo usuario' onClose={()=>props.handleClose()} open={open} className='header'>
                <Formik
                initialValues={{
                  name: user.nombre,
                  email: user.correo,
                  password: user.password,
                  phone: phone,
                  accounts:Object.keys(accounts).length === 0? {}: accounts,
                  defaultAccount: Object.keys(defaultAccount).length === 0? {}: defaultAccount
                }}
                enableReinitialize
                  validationSchema ={Yup.object({
                    name: Yup.string()
                      .required('Campo requerido'),
                    email: Yup.string().email('Correo electrónico inválido').required('Campo requerido'),
                    password: Yup.string().required('Campo requerido').min(6, 'La contraseña es muy corta - debe ser mínimo de 6 caracteres.').matches(
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/,
                      "La contraseña debe contener minúsucula, mayúscula y un número"
                    ),
                    phone: Yup.string().required('Campo requerido').min(12, 'Teléfono inválido'),
                    accounts: Yup.array().required('Cuenta por defecto requerida').nullable()
                    
                  })} 
                onSubmit={async(values, actions) => {
                  const infoNewUser = {
                    correo: values.email,
                    nombre: values.name,
                    password: values.password,
                    celular: values.phone,
                    perfil: defaultAccount.perfilName,
                    admin_cuenta: defaultAccount.admin_cuenta,
                    base_datos: defaultAccount.base_datos,
                    nodo_raiz: defaultAccount.nodo_raiz ? defaultAccount.nodo_raiz :null,
                    superset_activo: defaultAccount.superset_activo?defaultAccount.superset_activo:false,
                    superset_url: defaultAccount.superset_url?defaultAccount.superset_url:null
                                                 
                  };
                  try{
                    await firebase 
                    .createUser(infoNewUser)
                    .then(function (result) {
                      if(result === undefined || null){
                        actions.setSubmitting(false);
                        setErrorMessage('ERROR');
                        handleAlert('error');
                        setOpen(true);        
                        
                      }else{
                        setOpen(false);                                
                        handleAlert('success');
                        IdNewUser(infoNewUser.correo);                     
                      }                     
                      
                  });
                  }catch(error){          
                    throw new Error(error);                  
                  }
                  }        
                  }
              >
            {(formik,isSubmitting) => (
          <Form onSubmit={formik.handleSubmit}>
          
              <AppText
                id="name"
                name="name"
                type='text'
                label="Nombre de usuario"
                autoComplete="off"
                disabled={false}
                errors={formik.touched.name && !!formik.errors.name}
                errorMessage = {formik.touched.name && formik.errors.name}
                onChange ={(e) => {
                  setUser({...user, 'nombre': e.target.value});                
              }}           
               />            

              <AppText
                name = 'email'
                type='email'
                label="Correo electrónico"
                autoComplete="off"
                disabled={false}
                errors={formik.touched.email && !!formik.errors.email}
                errorMessage = {formik.touched.email && formik.errors.email}
                onChange ={(e) => {
                  setUser({...user, 'correo': e.target.value});                 
              }} 
               />
                            
              <AppPassword
                name = 'password'
                label="Contraseña"
                autoComplete="off"
                disabled={false}
                errors = {formik.touched.password && !!formik.errors.password}
                errorMessage = {formik.touched.password && formik.errors.password}
                onChange ={(e) => {
                  setUser({...user, 'password': e.target.value});                
                 }} 
              />              
            
              <AppPhone
                name = 'phone'
                type='phone'
                label="Celular"
                autoComplete="off"
                disabled={false}
                value={formik.values.phone}
                error = {formik.touched.phone && !!formik.errors.phone}
                onChange ={ (e) => {
                  setUser({...user, 'phone': e});
                  setPhone(e);                   
              }}
               />  
              <AppFormError errorMessage={formik.touched.phone && formik.errors.phone} />
                
                <div className='accounts_list'>
                <Grid container 
                direction="column"
                spacing={4} >
                <Grid item>
                  <Typography component="legend" ></Typography>
                </Grid>
                  <Grid item>
                    {isAccount?
                    <AppList
                        className='accounts'
                        title={'Cuentas'}
                        name = 'accounts'
                        inline = {true}
                        error = {formik.touched.accounts && !!formik.errors.accounts}
                        data = {formik.values.accounts}
                        create ={handlePopupAccount}
                        selected={accountsToEliminate}
                        onSelected={handleChange}
                        newUser={true}
                        onClick={handlePopupAccountSelected}
                        disabled={false}
                        colorTextData={[defaultAccount.accountId, 'Cuenta por defecto']}                  
                      />
                      :
                      <AppList
                        className='accounts'
                        title={'Cuentas'}
                        error = {formik.touched.accounts && !!formik.errors.accounts}
                        name = 'accounts'
                        inline = {true}
                        data = {{}}
                        create ={handlePopupAccount}
                        disabled={false}
                        colorTextData={[]}
                        
                      />
                      }
                  </Grid>
                  </Grid>
                  <AppFormError errorMessage={formik.touched.accounts && formik.errors.accounts} />
             
                  </div>
                
                  <AppButtonGroup variant="contained" aria-label="outlined primary button group" >
                    <AppButton variant='contained' tipo='modal-gris'onClick={props.handleClose}>
                      Cancelar
                    </AppButton>       
                    <AppButton variant='contained' tipo = 'modal-amarillos'type="submit">
                      Guardar
                    </AppButton>
                  </AppButtonGroup>
           
                   
            </Form>
            )}
        </Formik> 
           
            </AppPopup>
            </ErrorBoundary> 

            <PopupAssignAccount
             open = {popupOpenAssign}
             organizations = {organizations}
             defaultAccount = {setDefaultAccount}
             addNewAccount={addNewAccount}
             getAccountWithId ={props.getAccountWithId}
             handleClose ={handlePopupAccountClose}
             setOpen={setOpenPopupAssign}
             callRefresh={props.handleRefresh}
             firstAccount ={defaultAccount.length===0?true:false}
             newUser={true}

            />

        {accountSelected === undefined  || accountSelected === null? <h1> </h1>
          :
          <PopupAccountSelected
          open ={openAccountSelected}
          user={user}
          handleClose={handlePopupCancel}
          accountSelected ={accountSelected}
          disabled={false}
          handlePopupAccountSelected={handlePopupAccountSelected}
          newUser = {true}
          update = {updateAccountSelected}
          />

          }

             <AppSnackbar
              open = {successUser}
              onClose ={()=>handleAlert('success')}
              style = 'success'
            >
              ¡Se ha creado el usuario con éxito!
            </AppSnackbar>

            <AppSnackbar
              open = {errorOK}
              onClose ={()=>handleAlert('errorAccounts')}
              style = 'warning'
            >
              Ya existe un usuario con el correo ingresado
            </AppSnackbar>
            <AppSnackbar
              open = {errorAccounts}
              onClose ={()=>handleAlert('error')}
              style = 'warning'
            >
              Error al asignar cuentas al usuario
            </AppSnackbar>
          

        </div>
    );
}




export default PopupNewUser;
