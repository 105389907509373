import React, {useState} from 'react';

// Core
import AppPopup from '../../../core/components/Popup/AppPopup.js';
import AppSelect from '../../../core/components/Input/Select/AppSelect.js';
import AppButton from '../../../core/components/Button/AppButton.js';
import AppSwitch from '../../../core/components/Input/Switch/AppSwitch.js';
import AppMultiListField from '../../../core/components/Input/Text/AppMultiListField.js';
import AppSelectMultiple from '../../../core/components/Input/Select/AppSelectMultiple.js';

//Formik
import {Formik, Form} from 'formik';
import * as Yup from 'yup';

// Hooks
import useFetchAccountData from '../hooks/useFetchAccountData.tsx';
import useApiCall from '../hooks/useApiCall.tsx';

import './PopupPublicChanges.scss';
import {useFirebase} from '../../../core/context/firebase-context.js';

const PopupPublicRecords = ({
  handleClose,
  open,
  organizations,
  dbId,
  dataIdAccount,
  handleSnackBar,
}) => {
  // Custom Hook para manejar errores y exitos de las llamadas a la api
  const {loading, handleApiCall} = useApiCall();

  const firebase = useFirebase();

  const [availableAccountsDestination, setAvailableAccountsDestination] =
    useState(dataIdAccount);

  const testDatabase = process.env.REACT_APP_DB_TEST;

  // Función para filtrar las cuentas de origen solo mostrar las de pruebas
  const accountOrigin = dataIdAccount.reduce((result, account) => {
    if (account && account?.value?.includes(testDatabase)) {
      result.push({
        value: account.value,
        label: account.label !== undefined ? account.label : '-',
      });
    }
    return result;
  }, []);

  const {availableForms, getForms} = useFetchAccountData(
    organizations && organizations[dbId]
  );

  const initialValues = {
    accountOrigin: '',
    accountsDestination: [],
    selectForms: [],
    enableRecordsEmail: false,
    email: [],
  };

  // Validación de campos del formulario
  const validationObject = {
    accountOrigin: Yup.string().required('Cuenta origen requerida'),
    accountsDestination: Yup.array()
      .required()
      .test({
        name: 'accountsDestination',
        test: function (value) {
          if (Array.isArray(value) && value.length > 0) {
            return true;
          }
          return this.createError({
            message: 'Debe seleccionar al menos una cuenta destino',
          });
        },
      }),
    selectForms: Yup.array()
      .required()
      .test({
        name: 'selectForms',
        test: function (value) {
          if (Array.isArray(value) && value.length > 0) {
            return true;
          }
          return this.createError({
            message: 'Debe seleccionar al menos un formulario',
          });
        },
      }),
    enableRecords: Yup.boolean(),
    email: Yup.array()
      .of(
        Yup.string()
          .email('Ingresa un correo electrónico válido')
          .test({
            name: 'email',
            test: function (value) {
              if (value && value.endsWith('@galapagoagro.co')) {
                return true;
              }
              return this.createError({
                message: 'El correo debe terminar con "@galapagoagro.co"',
              });
            },
          })
      )
      .test({
        name: 'email',
        test: function (value) {
          if (Array.isArray(value) && value.length > 0) {
            const isValidEmails = value.every((email) =>
              email.endsWith('@galapagoagro.co')
            );
            if (!isValidEmails) {
              return this.createError({
                message:
                  'Todos los correos deben terminar con "@galapagoagro.co"',
              });
            }
          }
          return true;
        },
      }),
  };

  const handleFormSubmit = async (values, actions) => {
    const {base_datos, nodo_raiz} =
      organizations[dbId].cuentas[values.accountOrigin];

    const {selectForms, enableRecordsEmail, email, accountsDestination} =
      values;

    const requestBody = {
      databaseOrigin: base_datos,
      accountOrigin: nodo_raiz,
      nodesToPublish: selectForms.map((form) => form.value),
      ignoreGalappEmail: enableRecordsEmail ? 'Si' : 'No',
      exceptionEmail: email ? email : [],
    };

    const userToken = firebase.user._lat;

    const promises = accountsDestination.map(({root_node, database}) => {
      const requestPayload = {
        ...requestBody,
        accountDestination: root_node,
        databaseDestination: database,
      };
      return handleApiCall(
        'v1/data/replicate-records',
        requestPayload,
        userToken
      );
    });

    try {
      const results = await Promise.all(promises);

      await Promise.all(
        results.map(async (response, index) => {
          if (response.error) {
            let errorMessage = 'Error al publicar registros';
              if (
                Array.isArray(response.error.errors) &&
                response.error.errors[0]?.message
              ) {
                errorMessage = response.error.errors[0].message;
              } else if (
                Array.isArray(response.error) &&
                response.error[0]?.message
              ) {
                errorMessage = response.error[0].message;
              } else if (response.error.message) {
                errorMessage = response.error.message;
              }
            
            handleSnackBar({
              message: errorMessage,
              style: 'warning',
            });
          } else {
            handleSnackBar({
              message: 'Operación exitosa',
              style: 'success',
            });
            handleClose();
          }
        })
      );
    } catch (error) {
      handleSnackBar({
        message: 'Error al publicar registros',
        style: 'error',
      });
    }
  };

  return (
    <AppPopup title='Publicar registros' onClose={handleClose} open={open}>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={Yup.object(validationObject)}
        onSubmit={(values, actions) => {
          handleFormSubmit(values, actions);
        }}
      >
        {(formik, isSubmitting) => (
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit(e);
            }}
          >
            <>
              <div className='container-selec'>
                <AppSelect
                  id='accountOrigin'
                  name='accountOrigin'
                  label='Cuenta origen'
                  options={accountOrigin}
                  value={formik.values.accountOrigin}
                  errors={
                    formik.touched.accountOrigin &&
                    !!formik.errors.accountOrigin
                  }
                  errorMessage={
                    formik.touched.accountOrigin && formik.errors.accountOrigin
                  }
                  onChange={async (newValue) => {
                    const {value} = newValue.target;

                    formik.setFieldValue('accountOrigin', value);

                    const filteredAccounts = dataIdAccount.filter(
                      (account) => !value || account.value !== value
                    );
                    setAvailableAccountsDestination(filteredAccounts);
                    await getForms(value);
                  }}
                  disabled={!!formik.values.accountsDestination?.length}
                />
              </div>

              <div className='container-selec'>
                <AppSelectMultiple
                  id='accountsDestination'
                  name='accountsDestination'
                  options={availableAccountsDestination}
                  label='Cuentas destino'
                  value={formik.values.accountsDestination}
                  errors={
                    formik.touched.accountsDestination &&
                    !!formik.errors.accountsDestination
                  }
                  errorMessage={
                    formik.touched.accountsDestination &&
                    formik.errors.accountsDestination
                  }
                  onChange={(e) => {
                    formik.setFieldValue('accountsDestination', e);
                  }}
                />
              </div>
              <div className='container-selec-for'>
                <AppSelectMultiple
                  id='selectForms'
                  name='selectForms'
                  options={availableForms.filter(form=> form.type != 'anidado')}
                  label='Formularios'
                  value={formik.values.selectForms}
                  errors={
                    formik.touched.selectForms && !!formik.errors.selectForms
                  }
                  errorMessage={
                    formik.touched.selectForms && formik.errors.selectForms
                  }
                  onChange={(e) => {
                    formik.setFieldValue('selectForms', e);
                  }}
                />
              </div>
              <div className='container-switch '>
                <AppSwitch
                  name={'enableRecordsEmail'}
                  label={'Omitir registros @galapagoagro.co'}
                  labelLeft={'No'}
                  labelRight={'Si'}
                  className={'switch-button'}
                  checked={formik.values.enableRecordsEmail}
                  value={formik.values.enableRecordsEmail}
                  onChange={(e) => {
                    formik.setFieldValue('enableRecordsEmail', e);
                  }}
                />
              </div>
              <div className='container-inp'>
                <AppMultiListField
                  id='email'
                  name='email'
                  label='Correos excepción (Opcional) :'
                  variant='outlined'
                  disabled={formik.values.enableRecordsEmail === false}
                  value={formik.values.email}
                  errors={formik.touched.email && !!formik.errors.email}
                  errorMessage={formik.touched.email && formik.errors.email}
                  onChange={(value) => {
                    formik.setFieldValue('email', value);
                  }}
                />
              </div>

              <div className='container-btns'>
                <div className='container-headers'>
                  <AppButton
                    variant='contained'
                    tipo='modal-gris'
                    onClick={handleClose}
                  >
                    Cancelar
                  </AppButton>
                </div>
                <div className='container-headers'>
                  <AppButton
                    variant='contained'
                    disabled={isSubmitting}
                    tipo='modal-amarillos'
                    type='submit'
                    loading={loading}
                  >
                    Aceptar
                  </AppButton>
                </div>
              </div>
            </>
          </Form>
        )}
      </Formik>
    </AppPopup>
  );
};

export default PopupPublicRecords;
