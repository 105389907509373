import React, { useState } from 'react';
import { useFirebase } from '../../core/context/firebase-context';
import AppPopup from '../../core/components/Popup/AppPopup';
import AppErrorMessage from '../../core/components/ErrorMessage/AppErrorMessage';
import AppPagination from '../../core/components/Pagination/AppPagination';
import { paginate } from '../../core/components/Util/pagination';
import { dataFilteredFunction } from '../../core/components/Util/filter';
import AppSearch from '../../core/components/Search/AppSearch';
import { Formik } from 'formik';
import './index.scss';
import AppText from "../../core/components/Input/Text/AppText";

//MATERIAL
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

function Tags() {
  const firebase = useFirebase();
  const { create } = firebase.user.permissions.tags;

  const [tags, setTags] = React.useState([]);
  const [isLoading, setLoading] = React.useState(true);
  const [refresh, setRefresh] = React.useState(false);

  //editar tag
  const [popupEditTag, setPopEditTag] = useState(false);
  const [nameTag, setNameTag] = useState({name: '', id: ''});
  const [editTagBoolean, setEditTagBoolean] = useState(false);

  //crear tag
  const [popupNewTag, setPopupNewTag] = React.useState(false);
  const [message, setMessage] = useState('');

  //filtro
  const [categorySearched, setCategorySearched] = React.useState('');

  //paginacion
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  React.useEffect(() => {
    const title = document.getElementById('title');
    title ? (title.innerHTML = 'Etiquetas') : null;
    setLoading(true);
    const getTags = async () => {
      try {
        await firebase
          .allTags()
          .then(function (result) {
            setLoading(false);
            setTags(result);
          })
          .catch(function (error) {
            setLoading(false);
          });
      } catch (error) {
        //error
      }
    };
    getTags();
  }, [refresh]);

  const toggleNewTag = (el) => {
    setPopupNewTag(!popupNewTag);
  };

  const toggleEditTag = (name, id) => {
    setPopEditTag(!popupEditTag);
    setEditTagBoolean(false);
    typeof name === "string" ? setNameTag({name: name, id: id}) : name;
  };

  const createTag = async (values, actions) => {
    if (values.nombre === '') {
      setMessage('El campo nombre es obligatorio');
      setTimeout(function () {
        setMessage('');
      }, 4000);
      return;
    }
    await firebase.createTag(values);
    toggleNewTag();
    setRefresh(true);
  };

  const handleEditTag = async (values, actions) => {    
    if(editTagBoolean) {
      if (values.nombre === '') {
        setMessage('El campo nombre es obligatorio');
        setTimeout(function () {
          setMessage('');
        }, 4000);
    return;
      }
      await firebase.editTag(values.nombre, nameTag.id);
      setEditTagBoolean(!editTagBoolean);
      setRefresh(!refresh);
      toggleEditTag();
  } else {
      setEditTagBoolean(!editTagBoolean);
    }
  };

  if (isLoading) return <div> Loading</div>;

  //filtro
  const dataFiltered = dataFilteredFunction(tags, categorySearched, 'nombre');

  const appliedFilter = categorySearched.length ? dataFiltered : tags;

  const results = Object.keys(appliedFilter).map((el) => {
    const data = { ...appliedFilter[el] };
    return data;
  });

  const rows = paginate(results, page, rowsPerPage).map((item) => item);

  return (
    <section className='tags'>
      <div className='container_buttons-principal'>
        {
          create && 
           <div onClick={toggleNewTag}>
          <i className='mdi mdi-tag-outline'></i>Nueva Etiqueta
        </div>
        }

      </div>
      <div className='container_header'>
        <AppSearch
          categorySearched={categorySearched}
          setCategorySearched={setCategorySearched}
        />
      </div>
      <List
        component='nav'
        aria-label='secondary mailbox folders'
        className='list'>
        {rows.map(function (el, index) {
          return (
            <ListItem button key={index}>
              <ListItemText onClick={() => toggleEditTag(el.nombre, el.id)} primary={`Nombre: ${el.nombre}`} />
            </ListItem>
          );
        })}
      </List>
      <AppPagination
        results={results}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
      <AppPopup title='Editar Etiqueta' onClose={toggleEditTag} open={popupEditTag}>
        <Formik initialValues={{ nombre: ''}} onSubmit={handleEditTag}>
          {(props) => (
            <form onSubmit={props.handleSubmit} style={{marginTop: "20px"}}>
              <AppText
                id={nameTag.id}
                name='nombre'
                label='Nombre Etiqueta'
                onChange={props.handleChange}
                value={props.values.name}
                defaultValue={nameTag.name}
                InputProps={{
                  readOnly:  editTagBoolean ? false : true,
                }}
              />
              <div className='button-confirm-container'>
                <Button variant='contained' onClick={toggleEditTag}>
                  Cancelar
                </Button>
                <Button
                  variant='contained'
                  tipo={'modal-amarillos'}
                  className='btn-guardar'
                  type='submit'>
                  {editTagBoolean ? "Guardar" : "Editar"}
                </Button>
              </div>
              <AppErrorMessage
                message={message}
                setMessage={setMessage}></AppErrorMessage>
            </form>
          )}
        </Formik>
      </AppPopup>
      <AppPopup title='Nueva etiqueta' onClose={toggleNewTag} open={popupNewTag}>
        <Formik initialValues={{ nombre: '' }} onSubmit={createTag}>
          {(props) => (
            <form onSubmit={props.handleSubmit} style={{marginTop: "20px"}}>
              <AppText 
                id={nameTag.id}
                name='nombre'
                label='Nombre Etiqueta'
                onChange={props.handleChange}
                value={props.values.name}
              />
              <div className='button-confirm-container'>
                <Button variant='contained' onClick={toggleNewTag}>
                  Cancelar
                </Button>
                <Button
                  variant='contained'
                  className='btn-guardar'
                  type='submit'
                  tipo={'modal-amarillos'}>
                  Guardar
                </Button>
              </div>
              <AppErrorMessage
                message={message}
                setMessage={setMessage}></AppErrorMessage>
            </form>
          )}
        </Formik>
      </AppPopup>
    </section>
  );
}

export default Tags;
