import React from 'react';
import AppSnackbar from '../Snackbar/AppSnackbar';

function ToastMessages({ toasts, onClose }) {
  return toasts.map(({ id, type, message, open }) => {
    return (
      <AppSnackbar
        key={id}
        id={`galapp-toast-${id}`}
        style={type}
        open={open}
        onClose={(_, reason) => {
          if (reason === 'clickaway') {
            return;
          }
          onClose(id);
        }}
      >
        {message}
      </AppSnackbar>
    );
  });
}

export default ToastMessages;
