import axios from 'axios';
import { useFirebase } from '../core/context/firebase-context';

const BASE = process.env.REACT_APP_BASE_URL;

export const useAxiosInstance = () => {
  const { getToken } = useFirebase();

  const anonInstance = axios.create({
    baseURL: BASE,
  });

  anonInstance.interceptors.request.use(async (config) => {
    const token = await getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  anonInstance.interceptors.response.use(
    (response) => {
      return response.data;
    },
    (error) => {
      throw error;
    }
  );

  return anonInstance;
};