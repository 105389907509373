import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';
import './appTableBackup.scss';

function AppTableBackup({ data, onShowDetails, columnsToShow, formatDate }) {
  const handleShowDetails = (item) => {
    onShowDetails(item);
  };
  const dataArray = Array.isArray(data) ? data : Object.values(data);

  return (
    <>
      <TableContainer component={Paper}>
        <Table className='table-container'>
          <TableHead>
            <TableRow>
              {columnsToShow.map((columnName) => (
                <TableCell key={columnName} className='scrollableCell'>
                  {columnName}
                </TableCell>
              ))}
              <TableCell className='fixedCell'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataArray.map((item, index) => (
              <TableRow key={index}>
                {columnsToShow.map((columnName) => (
                  <TableCell key={columnName} className='scrollableCell'>
                    {columnName === 'fecha_servidor' && item.fecha_servidor?.seconds && item.fecha_servidor?.nanoseconds ? (
                      formatDate(item.fecha_servidor.seconds, item.fecha_servidor.nanoseconds)
                    ) : (
                      item[columnName]
                    )}
                  </TableCell>
                ))}

                <TableCell className='fixedCell cell' >
                  <Button onClick={() => handleShowDetails(item)}>Ver detalle</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default AppTableBackup;
