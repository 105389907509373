import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

import AppJsonDetails from './AppJsonDetails';
import AppListMetadata from './AppListMetadata';
import AppSnackbar from "../../core/components/Snackbar/AppSnackbar";
import AppRestart from './AppRestart';
import IconLoading from './IconLoading';

import { useFirebase } from '../../core/context/firebase-context';

import './appInfoDetails.scss';



const AppInfoDetails = () => {

    //Firebase
    const { getDocumentBackup, getAccountName, restoreRecord, user } = useFirebase();

    const { doc_id } = useParams();

    const [loading, setLoading] = useState(true);
    const [selectedItem, setSelectedItem] = useState(null);
    const [accountName, setAccountName] = useState(null);
    const [restoring, setRestoring] = useState(false);
    const [open, setOpen] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [error, setError] = useState('');

    //Modal
    const handleToggleModal = () => {
        setOpen(!open);
    };

    useEffect(() => {
        const title = document.getElementById('title');
        title ? (title.innerHTML = 'Explorador backup') : null;
    }, []);

    //Formatear fecha servidor
    const formatDate = (seconds, nanoseconds) => {
        const milliseconds = seconds * 1000 + nanoseconds / 1000000;
        const date = new Date(milliseconds);

        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            timeZoneName: 'short',
        };

        return new Intl.DateTimeFormat('es-ES', options).format(date);
    };

    //Data de la colección de acuerdo al id
    useEffect(() => {
        const fetchData = async () => {
            const _selectedItem = await getDocumentBackup(doc_id);

            if (_selectedItem) {
                setSelectedItem(_selectedItem);
                const accountName = await getAccountName(_selectedItem.account_key);
                setAccountName(accountName);
                setLoading(false);
            }
        };

        fetchData();
    }, [doc_id]);

    //Metadata
    const metadata = [
        { label: 'Cuenta', value: accountName },
        { label: 'account_key', value: selectedItem?.account_key || '' },
        { label: 'operacion', value: selectedItem?.operacion || '' },
        { label: 'fuente', value: selectedItem?.fuente || '' },
        { label: 'formulario', value: selectedItem?.formulario || '' },
        { label: 'fechaCreacion', value: selectedItem?.fechaCreacion || '' },
        { label: 'fechaModificacion', value: selectedItem?.fechaModificacion || '' },
        { label: 'fechaEliminacion', value: selectedItem?.fechaEliminacion || '' },
        {
            label: 'fecha_servidor',
            value: selectedItem?.fecha_servidor && (
                <>
                    {formatDate(selectedItem.fecha_servidor.seconds, selectedItem.fecha_servidor.nanoseconds)}
                </>
            )
        },
        { label: 'emailCreacion', value: selectedItem?.registro && JSON.parse(selectedItem.registro)?.emailCreacion || '' },
        { label: 'emailModificacion', value: selectedItem?.registro && JSON.parse(selectedItem.registro)?.emailModificacion || '' }
    ];


    //Restaurar registros
    const restoreLog = async () => {
        setRestoring(true);

        const payload = {
            ...selectedItem,
            fechaModificacion: selectedItem.fechaModificacion,
            usuarioModificacion: user.uid,
            registro: {
                ...JSON.parse(selectedItem.registro)
            },
            user_restore: {
                emailUserRestore: user.email,
                uidUserRestore: user.uid,
              },
        };

        if (!selectedItem.fechaCreacion ||
            !selectedItem.fechaModificacion ||
            !(selectedItem.registro &&
                JSON.parse(selectedItem.registro).emailCreacion) ||
            !(selectedItem.registro &&
                JSON.parse(selectedItem.registro).emailModificacion) ||
            !selectedItem.usuarioCreacion ||
            !selectedItem.usuarioModificacion) {
            setError('Faltan campos obligatorios para restaurar');
            setRestoring(false);
            return;
        }
        try {
            await restoreRecord(payload);
            handleToggleModal();
            setOpenSnackbar(true);
        } catch (error) {
            console.error('Error:', error);
            setError('No fue posible restaurar el registro');
        } finally {
            setRestoring(false);

        }
    };


    return (
        <div className='container-info-details'>
            {loading ? (
                <IconLoading />
            ) : (
                <div className='container-json-metadata'>
                    <AppListMetadata accountName={accountName} selectedItem={selectedItem} data={metadata} />
                    <AppJsonDetails
                        selectedItem={selectedItem}
                        showSeeMore={false}
                    />
                    <AppRestart selectedItem={selectedItem} handleToggleModal={handleToggleModal} error={error} open={open} setOpen={setOpen} handleRestore={restoreLog} restoring={restoring} />
                    <AppSnackbar
                        open={openSnackbar}
                        onClose={() => setOpenSnackbar(false)}
                        style="success"
                    >
                        ¡El registro ha sido restaurado con éxito!
                    </AppSnackbar>
                </div>

            )}
        </div>
    );
};

export default AppInfoDetails;
