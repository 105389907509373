import React from 'react';
import TablePagination from '@material-ui/core/TablePagination';

function Pagination(props) {

  const handleChangePage = (event, page) => {
    props.setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    props.setRowsPerPage(+event.target.value);
    props.setPage(0);
  };


  return (
    <TablePagination
      count={props.results.length}
      rowsPerPage={props.rowsPerPage}
      page={props.page}
      rowsPerPageOptions={[5, 10, 25]}
      component='div'
      labelRowsPerPage='Filas por página'
      labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
}

export default Pagination;
