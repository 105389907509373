import React, { useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { generatePath } from 'react-router';
import { useFirebase } from '../../core/context/firebase-context';
import { paginate } from '../../core/components/Util/pagination';
import axios from 'axios';
import * as Yup from "yup";


import { Form, Formik } from 'formik';
import { Button, TextField } from '@material-ui/core';
import { Alert, AlertTitle } from '@mui/material';

import AppPagination from '../../core/components/Pagination/AppPagination';
import AppJsonDetails from './AppJsonDetails';
import AppTableBackup from './AppTableBackup';
import AppButton from "../../core/components/Button/AppButton";
import "./index.scss";
import FormError from '../../core/components/FormError/AppFormError';



function ExploradorBackup() {
    const { user } = useFirebase();
    const { fb_id } = useParams();
    const history = useHistory();

    const [resultAlert, setResultAlert] = useState(true);
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [selectedItem, setSelectedItem] = useState(null);
    const [expanded, setExpanded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const apiUrlEnv = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        const title = document.getElementById('title');
        title ? (title.innerHTML = 'Explorador backup') : null;
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            if (fb_id) {
                await filteredBackup(fb_id);
            }
        };
    
        fetchData();
    }, [fb_id]);


    //Maneja el cambio de searchTerm
    useEffect(() => {
        if (searchTerm) {
            setPage(0);
        }
    }, [searchTerm]);

    //Petición
    const filteredBackup = (fb_id) => {
        setLoading(true);
        setResultAlert(false);
        const url = `${apiUrlEnv}/v1/data/history/${fb_id}`;
        const token = user.ya;
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        return axios.get(url, config)
            .then(response => {
                const data = response.data.data;
                if (data) {
                    setErrorMessage('');
                    const filteredData = Object.entries(data).filter(([id, item]) => item.fb_id === fb_id);
                    const addIdData = filteredData.map(([id, item]) => ({ ...item, id }));

                    if (addIdData.length > 0) {
                        setData(addIdData);
                        setPage(0);
                        setLoading(false);
                    }else {
                        setLoading(false);
                        setData([]);
                        setErrorMessage('No se encontraron registros');
                    }
                    return addIdData;
                }
                
            })
            .catch(error => {
                console.error(error);
                setData([]);
                setLoading(false);
                return [];
            });
    };



    //Paginación
    const paginatedData = paginate(data, page, rowsPerPage);

    //Nombres de las columnas de la tabla
    const columnsToShow = ['id', 'account_key', 'fb_id', 'fecha', 'fechaCreacion', 'fechaModificacion', 'formulario', 'fuente', 'operacion', 'usuarioCreacion', 'usuarioModificacion', 'fecha_servidor'];


    //Ver más detalles
    const handleShowDetails = (item) => {
        setSelectedItem(item);
        setExpanded(true);
    };

    const handleCloseDetails = () => {
        setSelectedItem(null);
        setExpanded(false);
    };

    const returnPage = () => {
        history.push('/');
    };

    //Formatear fecha servidor
    const formatDate = (seconds, nanoseconds) => {
        const milliseconds = seconds * 1000 + nanoseconds / 1000000;
        const date = new Date(milliseconds);

        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            timeZoneName: 'short',
        };

        return new Intl.DateTimeFormat('es-ES', options).format(date);
    };


    return (
        <div className={`container-explorador-backup${expanded ? ' expanded' : ''}`}>

            <div className='container-table-json'>
                <div className='container-table-pagination'>
                    <p>Buscar registro</p>
                    <Formik
                        initialValues={{ fb_id: fb_id ? fb_id : '' }}
                        validationSchema={Yup.object({
                            fb_id: Yup.string()
                                .required("Ingrese un fb_id")
                        })}
                        onSubmit={async (values, actions) => {
                            const path = generatePath("/explorador-backup/:fb_id", { fb_id: values.fb_id });
                            history.replace(path);
                        }}
                    >

                        {(formik, isSubmitting) => (
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    formik.handleSubmit(e);
                                }}
                            >
                                <p>fb_id</p>
                                <TextField
                                    id="fb_id"
                                    name="fb_id"
                                    type="text"
                                    fullWidth
                                    value={formik.values.fb_id}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.fb_id && !!formik.errors.fb_id
                                    }
                                    helperText={
                                        formik.touched.fb_id && formik.errors.fb_id
                                    }
                                />
                                <AppButton
                                    type="submit"
                                    disabled={isSubmitting}
                                    tipo="buscar"
                                    loading={loading}
                                >
                                    Buscar
                                </AppButton>
                            </Form>
                        )}
                    </Formik>
                        {errorMessage && <FormError errorMessage={errorMessage} />}

                    {data.length > 0 && (
                        <>
                            <AppTableBackup
                                data={paginatedData}
                                onShowDetails={handleShowDetails}
                                expanded={expanded}
                                columnsToShow={columnsToShow}
                                formatDate={formatDate}
                            />
                            <AppPagination
                                results={data}
                                page={page}
                                setPage={setPage}
                                rowsPerPage={rowsPerPage}
                                setRowsPerPage={setRowsPerPage}
                            />
                        </>                      
                        
                    )}
                    <div className='button-container'>
                        <Button className='button_return' onClick={returnPage}>Regresar</Button>
                    </div>

                </div>
                {selectedItem && (
                    <div className='container-json'>
                        <AppJsonDetails
                            selectedItem={selectedItem}
                            onClose={handleCloseDetails}
                            text='Cancelar'
                            showSeeMore={true}
                            expanded={expanded}
                        />
                    </div>
                )}
            </div>

        </div >
    );
}

export default ExploradorBackup;
