import React, { useState } from 'react';

import { Button, Menu, MenuItem, InputLabel} from '@material-ui/core';
import AppButton from '../../../core/components/Button/AppButton';

import * as XLSX from 'xlsx';
import * as Papa from 'papaparse';
import './ExportFile.scss';

const ExportFile = ({ data, fileName }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedOption, setSelectedOption] = useState('');
    const exportToExcel = () => {
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
        XLSX.writeFile(wb, `${fileName}.xlsx`);
    };

    const exportToCSV = () => {
        const csv = Papa.unparse(data);
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${fileName}.csv`;
        a.click();
        URL.revokeObjectURL(url);
    };
    
    const handleButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
      const handleMenuItemClick = (value) => {
        setSelectedOption(value);
        setAnchorEl(null);
        handleDownload(value);
      };
    
      const handleCloseMenu = () => {
        setAnchorEl(null);
      };
    
      const handleDownload = (option) => {
        if (option === 'excel') {
          exportToExcel();
        } else if (option === 'csv') {
          exportToCSV();
        }
      };

    return (

    <div className='container-Expor'>
      <div className='selectContainer'>
        <AppButton
              variant='contained'
              tipo='yellow-button'
              id='fixedbutton'
              onClick={handleButtonClick}
            >
              Exportar
            </AppButton>

        <Menu
          id="format-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <MenuItem onClick={() => handleMenuItemClick('excel')}>Excel</MenuItem>
          <MenuItem onClick={() => handleMenuItemClick('csv')}>CSV</MenuItem>
        </Menu>
      </div>
    </div>
    );
};

export default ExportFile;