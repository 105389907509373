import React from 'react';
import PropTypes from 'prop-types';
import MaterialButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from "@material-ui/core/styles";

import './AppButton.scss';

function Button({ children, loading, ...props }) {
  const styles = {
    buttonBuscar: {
      background: "#ffb81d",
      borderRadius: 3,
      color: "#000000",
      height: 48,
      padding: "0 30px",
      marginTop: "10px",
      justifyContent: 'center',
      "&:hover": {
        background: "#ffb81d"
      },
    },
    buttonsModalYellow: {
      background: "#ffb81d",
      border: 0,
      borderRadius: 3,
      color: "#000000",
      height: 38,
      padding: "0 12px",
      marginBottom: '30px',
      "&:hover": {
        background: "#ffb81d"
      },
    },
    buttonsModalGray: {
      border: 0,
      borderRadius: 3,
      color: "#000000",
      background: '#e0e0e0',
      height: 38,
      padding: "0 12px",
      // marginBottom: '30px',
    },

    buttonsContained:{
      border: 0,
      borderRadius: 3,
      height: 38,
      padding: "0 10px",
      marginBottom: '0px',
      marginLeft: '50px'
    },

    buttonYellow:{
      background: "#ffb81d",
      border: 0,
      borderRadius: 3,
      color: "#000000",
      height: 38,
      padding: "0 12px",
      "&:hover": {
        background: "#ffb81d"
      },
    }
  };
  
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  return (
    <div>
      { props.tipo == 'buscar'?
      <MaterialButton {...props} className={classes.buttonBuscar}>
      {loading ? <CircularProgress size={20} /> : children}
      </MaterialButton>
      :
      props.tipo == 'modal-amarillos'?
      <MaterialButton {...props} className={classes.buttonsModalYellow}>
      {loading ? <CircularProgress size={20} /> : children}
      </MaterialButton>
      :
      props.tipo == 'modal-contained'?
      <MaterialButton {...props} className={classes.buttonsContained}>
      {loading ? <CircularProgress size={20} /> : children}
      </MaterialButton>
      :
      props.tipo=='yellow-button'?
      <MaterialButton {...props} className={classes.buttonYellow}>
      {loading ? <CircularProgress size={20} /> : children}
      </MaterialButton>
      :
      <MaterialButton {...props} className={classes.buttonsModalGray}>
      {loading ? <CircularProgress size={20} /> : children}
      </MaterialButton>


      }
        
    </div>
    
  );
}

Button.propTypes = {
  children: PropTypes.any,
  loading: PropTypes.bool,
};

export default Button;