import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

//Componentes core
import { useFirebase } from '../../core/context/firebase-context';
import AppPagination from '../../core/components/Pagination/AppPagination';
import { paginate } from '../../core/components/Util/pagination';
import AppSearch from '../../core/components/Search/AppSearch';
import { dataFilteredFunction } from '../../core/components/Util/filter';
import AppSnackbar from '../../core/components/Snackbar/AppSnackbar';

import './styles/index.scss';

//Material UI
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

//Popups
import PopupNewCompany from './PopupNewCompany';

/**
 * Component for displaying a list of organizations.
 */

function Organizations() {
  const firebase = useFirebase();

  const { create } = firebase.user.permissions.organizations;


  const [organizations, setOrganizations] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);

  //Crear organización
  const [popupNewCompany, setPopupNewCompany] = useState(false);

  //Filtro
  const [categorySearched, setCategorySearched] = useState('');

  //Paginación
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  //Snackbar
  const [snackbarProps, setSnackBarProps] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const getCompanies = async () => {
    try {
      setLoading(true);
      const result = await firebase.getOrganizations();
      setOrganizations(result);
    } catch (error) {
      console.error('Error fetching organizations:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const title = document.getElementById('title');
    if (title) {
      title.innerHTML = 'Organizaciones';
    }
    getCompanies();
  }, [refresh]);

  /**
   * Updates the organizations state with a new organization.
   * @param {string} idOrg - The ID of the organization to update.
   * @param {object} newOrganization - The new organization data.
   */
  const updateOrganizations = () => {
    setRefresh(prev => !prev); 
  };

  const handleNewCompany = () => {
    setPopupNewCompany(!popupNewCompany);
  };

  if (isLoading) return <div> Loading</div>;

  //filtro
  const dataFiltered = dataFilteredFunction(organizations, categorySearched, 'nombre');

  const appliedFilter = categorySearched.length ? dataFiltered : organizations;

  const results = Object.keys(appliedFilter).map((el) => {
    const data = { ...appliedFilter[el], key: el };
    return data;
  });

  const rows = paginate(results, page, rowsPerPage).map((item) => item);

  const handleSnackBar = (properties) => {
    setSnackBarProps(properties);
    setOpenSnackbar(true);
  };

  const handleAlerts = () => {
    setOpenSnackbar(false);
  };

  return (
    <section className='organizations'>
      <div className='container_buttons-principal'>
        {
          create && <div onClick={handleNewCompany}>
          <i className='mdi mdi-plus-circle-outline'></i>Crear
        </div>
        }
        
      </div>
      <div className='container_header'>
        <AppSearch
          categorySearched={categorySearched}
          setCategorySearched={setCategorySearched}
        />
      </div>
      <List
        component='nav'
        aria-label='secondary mailbox folders'
        className='list'>
        {rows.map(function (el, index) {
          return (
            <ListItem
              component={Link}
              to={{
                pathname: `/organizations/${el.key}`,
                state: {
                  organization: organizations[el.key],
                  orgs: organizations,
                  dbId: el.key,
                },
              }}
              button
              key={index}
            >
              <ListItemText primary={`Nombre: ${el.nombre}`} />
            </ListItem>
          );
        })}
      </List>
      <AppPagination
        results={results}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
      <PopupNewCompany
        open={popupNewCompany}
        handleSnackBar={handleSnackBar}
        handleClose={handleNewCompany}
        setRefresh={setRefresh}
        updateOrganizations={updateOrganizations}
        orgs={organizations}
      />
      <AppSnackbar
        open={openSnackbar}
        onClose={handleAlerts}
        style={snackbarProps.style}
      >
        {snackbarProps.message}
      </AppSnackbar>


    </section>
  );
}

export default Organizations;
