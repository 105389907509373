import React from 'react';
import InputBase from '@material-ui/core/InputBase';

function Search(props) {
  const handleFilterChange = (e) => {
    let value = e.target.value;
    props.setCategorySearched(value);
  };

  return (
    <div className='search'>
      <div className='container_search'>
        {props.showLabel ? (
          <p>fb_id</p>
        ) : (
          <i className='mdi mdi-magnify'></i>
        )}
        <InputBase
          autoFocus={true}
          placeholder='Filtrar'
          value={props.categorySearched}
          onChange={handleFilterChange}
        />

      </div>
      {props.showError && props.filteredData.length === 0 && (
        <div className='error-message'>
          Introduce un valor válido para la búsqueda.
        </div>
      )}
    </div>
  );
}

export default Search;
