import React, { useState, useEffect } from 'react';
import { useFirebase } from '../../core/context/firebase-context';

//Componentes core
import AppPopup from '../../core/components/Popup/AppPopup';
import AppList from '../../core/components/Input/List/AppList';
import AppText from '../../core/components/Input/Text/AppText';


//Formik
import { Formik, Form} from 'formik';
import * as Yup from "yup";

import './styles/index.scss';

//Material UI
import AppButton from '../../core/components/Button/AppButton';
import AppButtonGroup from '../../core/components/ButtonGroup/AppButtonGroup.js';

import PopupNewAccount from './PopupNewAccount';
import {formatAccount} from './utils/formatArrayOptions';
import useApiCall from './hooks/useApiCall';

/**
 * Represents a popup for creating a new company organization.
 * @param {Object} props - The component's props.
 * @param {Object} props.orgs - The existing organizations.
 * @param {Function} props.handleClose - A function to close the popup.
 * @param {boolean} props.open - A boolean indicating whether the popup is open.
 * @param {Function} props.updateOrganizations - A function to update the list of organizations.
 * @param {Function} props.handleSnackBar - A function to handle the display of snackbars.
 */

function PopupNewCompany({
  orgs,
  handleClose,
  open,
  updateOrganizations,
  handleSnackBar,
}) {
  const firebase = useFirebase();

  const [accounts, setAccounts] = useState([]);
  const [accountSelected, setAccountSelected] = useState({});
  const [nameOrganization, setNameOrganization] = useState('');
  const [IDOrganization, setIDOrganization] = useState('');
  const [openNewAccount, setOpenNewAccount] = useState(false);

  const [orgsFormat, setOrganizations] = useState();
  const [loading, setLoading] = useState(false);

  const {handleApiCall} = useApiCall();

  useEffect(() => {
    if (!open) {
      clearValues();
    }
  }, [open]);

  const handleNewAccount = () => {
    let organizations = {...orgs};
    if (nameOrganization.length > 0) {
      organizations = {
        ...orgs,
        [nameOrganization]: {id: nameOrganization, nombre: nameOrganization},
      };
    }
    const orgformat = Object.entries(organizations).map(([key, value]) => ({
      value: value.id,
      label: value.nombre,
    }));

    setOrganizations(orgformat);
    setOpenNewAccount(!openNewAccount);
    !openNewAccount && setAccountSelected({});
  };

  const handleSelectedAccount = (account) => {
    setAccountSelected(account);
    setOpenNewAccount(true);
  };

  const orgExists = (values) => {
    const { name, id } = values;

    let exists = false;

    if (id) {
        exists = Object.values(orgs).some((org) => org.id === id);
        if (exists) {
            setLoading(false);
            handleSnackBar({
                message: 'Error: el código de la organización ya existe',
                style: 'warning',
            });
        }
    }

    if (!exists && name) {
        exists = Object.values(orgs).some((org) => org.nombre === name);
        if (exists) {
            setLoading(false);
            handleSnackBar({
                message: 'Error: el nombre de la organización ya existe',
                style: 'warning',
            });
        }
    }

    return exists;
};

const addAccountToOrganization = (account) => {
  const existingAccountIndex = accounts.findIndex(cuenta => cuenta.idElement === account.idElement);

  if (existingAccountIndex !== -1) {
    const updatedAccounts = accounts.map((cuenta, index) => 
      index === existingAccountIndex
        ? {
            ...cuenta,
            ...formatAccount(account, accounts, nameOrganization, index),
          }
        : cuenta
    );
    setAccounts(updatedAccounts);
  } else {
    setAccounts([...accounts, formatAccount(account, accounts, nameOrganization, accounts.length)]);
  }
};

  const clearValues = () => {
    setNameOrganization('');
    setIDOrganization('');
    setAccounts([]);
  };

  const initialOrganizationValues = {
    name: nameOrganization,
    id: IDOrganization,
    accounts: accounts,
  };

  const validationObject = {
    name: Yup.string()
      .required('Campo requerido')
      .max(50, 'El nombre debe ser máximo de 50 caracteres'),
    id: Yup.string()
      .required('Campo requerido')
      .matches(
        /^[a-z|A-Z|_|]+$/,
        "El código de organización solo debe tener letras y/o '_'"
      )
      .min(3, 'El código de organización debe tener al menos 3 caracteres')
      .max(10, 'El código de organización debe ser máximo de 10 caracteres'),
  };

  const handleOnSubmit = async (values, actions) => {
    setLoading(true);
  
    const requestBody = {
      ...values,
      accounts: accounts
    };
  
    const oEx = orgExists(values);
    if (oEx) {
      setLoading(false);
      return;
    }
  
    const accIds = values.accounts?.flatMap((item) => item.id);
    const isDuplicated = accIds && accIds.length !== new Set(accIds).size;
  
    if (!values.accounts || values.accounts.length === 0) {
      handleSnackBar({
        message: 'Error: La Organización debe tener al menos una cuenta',
        style: 'warning',
      });
      setLoading(false);
      return;
    }
  
    if (isDuplicated) {
      handleSnackBar({
        message: 'Error: El ID de la cuenta debe ser único por tipo de cuenta.',
        style: 'warning',
      });
      setLoading(false);
      return;
    }
  
    try {
      const userToken = firebase.user._lat;
      const response = await handleApiCall('v1/organization', requestBody, userToken);
 
      if (response.error) {
        handleSnackBar({
          message: `Error: ${response.error.message}`,
          style: 'warning',
        });
        setLoading(false);
        return;
      }
      
      clearValues();
      handleClose();
      handleSnackBar({
        message: `Organización ${values.name} fue creada con éxito`,
        style: 'success',
      });
      updateOrganizations();
    } catch (error) {
      handleSnackBar({
        message: 'Error: No se pudo crear la organización. Por favor, inténtelo de nuevo más tarde.',
        style: 'warning',
      });
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <section>
      <AppPopup title='Nueva Organización' onClose={handleClose} open={open}>
        <Formik
          initialValues={initialOrganizationValues}
          enableReinitialize
          validationSchema={Yup.object(validationObject)}
          onSubmit={async (values, actions) =>
            await handleOnSubmit(values, actions)
          }
        >
          {(formik, isSubmitting) => (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit(e);
              }}
            >
              <AppText
                id='name'
                name='name'
                label='Nombre de organización'
                value={formik.values.name}
                errors={formik.touched.name && !!formik.errors.name}
                errorMessage={formik.touched.name && formik.errors.name}
                onChange={(e) => {
                  setNameOrganization(e.target.value);
                  formik.setFieldValue('name', e.target.value);
                }}
              />

              <AppText
                id='id'
                name='id'
                label='Código organización: Ej. LEV, STRC'
                value={formik.values.id}
                errors={formik.touched.id && !!formik.errors.id}
                errorMessage={formik.touched.id && formik.errors.id}
                onChange={(e) => {
                  formik.setFieldValue('id', e.target.value);
                  setIDOrganization(e.target.value);
                }}
              />

              <AppList
                title='Cuentas'
                data={accounts}
                create={handleNewAccount}
                colorTextData={['', 'Cuenta actual']}
                disabled={false}
                newAccount={true}
                onClick={handleSelectedAccount}
              />

              <AppButtonGroup
                variant='contained'
                aria-label='outlined primary button group'
                className='button-confirm-container'
              >
                <AppButton variant='contained' onClick={handleClose}>
                  Cancelar
                </AppButton>
                <AppButton
                  variant='contained'
                  className='btn-guardar'
                  disabled={isSubmitting}
                  loading={loading}
                  tipo='yellow-button'
                  type='submit'
                >
                  Guardar
                </AppButton>
              </AppButtonGroup>
            </Form>
          )}
        </Formik>
      </AppPopup>

      <PopupNewAccount
        open={openNewAccount}
        handleClose={handleNewAccount}
        organizations={orgsFormat}
        nameOrganization={nameOrganization}
        onSubmit={addAccountToOrganization}
        accountSelected={accountSelected}
        handleSnackBar={handleSnackBar}
        newOrganization={true}
      />
    </section>
  );
}

export default PopupNewCompany;
