import React, { useState, useEffect } from "react";
import { useFirebase } from "../../core/context/firebase-context";
import { ErrorBoundary } from "react-error-boundary";
import { useHistory } from 'react-router-dom';

//Formik
import { Formik, Form } from "formik";
import * as Yup from "yup";
import "./index.scss";
import AppFormError from '../../core/components/FormError/AppFormError';
import AppSwitch from "../../core/components/Input/Switch/AppSwitch";
//Material
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import { Checkbox, CircularProgress, FormControlLabel, Grid, InputLabel, MenuItem, Popper, Select } from "@material-ui/core";

//Componentes core
//import AppPopup from "../../core/components/Popup/AppPopup";
import AppButton from "../../core/components/Button/AppButton";
import AppList from "../../core/components/Input/List/AppList";
import AppPhone from "../../core/components/Input/Phone/AppPhone";
import AppButtonGroup from "../../core/components/ButtonGroup/AppButtonGroup";
import AppSnackbar from "../../core/components/Snackbar/AppSnackbar";
import AppTable from "../../core/components/Table/AppTable";
import AppSearch from '../../core/components/Search/AppSearch';
import AppPagination from '../../core/components/Pagination/AppPagination';
import AppAutocomplete from '../../core/components/Input/Autocomplete';
//AppPopups
import PopupAssingAccount from "./PopupAssignAccount";
import PopupAccountSelected from "./PopupAccountSelected";
import { PopoupRecoverPassword } from "./PopoupRecoverPassword";
import useUserManager from './hooks/useUserManager';
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { paginate } from '../../core/components/Util/pagination';
import { dataFilteredFunction } from '../../core/components/Util/filter';
import AppEndButtons from "../../core/components/EndButtons/AppEndButtons";
import AppSelectMultiple from "../../core/components/Input/Select/AppSelectMultiple";

/**
 * Component for editing user information.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {Object} props.user - User information to be edited.
 * @param {boolean} props.open - Indicates whether the component is open or closed.
 * @param {function} props.setDefaultAccount - Function to set the default account.
 * @param {function} props.setAccountEdited - Function to update the edited user account.
 * @param {function} props.setUserNewAccount - Function to assign a new account to the user.
 * @param {function} props.setInactiveAccount - Function to deactivate a user account.
 * @param {function} props.getAccountWithId - Function to retrieve account information by ID.
 * @param {function} props.getAllOrganizations - Function to retrieve information for all organizations.
 * @returns {JSX.Element} User edit component.
 */

function AppPopupEditUser(props) {

  const firebase = useFirebase();
  const {edit, change_password, admin_access} = firebase.user.permissions.users;
  const { uid } = useParams();
  let history = useHistory();

  const {
    loading,
    setLoading,
    user,
    setUser,
    getAllOrganizations,
    getAccountWithId,
    getUserToChange,
    setUserNewAccount,
    setDefaultAccount,
    setInactiveAccount,
    setAccountEdited,
  } = useUserManager();

  //Cuentas info
  const [accountsInact, setInact] = useState([]);
  const [selectedOptions, setOptions] = useState("");
  const [accountSelected, setAccountSelected] = useState({});
  const [roles, setRoles] = useState([]);

  //Popups
  const [popupOpenAccountSelected, setOpenPopupAccountSelected] = useState(false);
  const [popupOpenAssign, setOpenPopupAssign] = useState(false);
  const [popupOpenRecoverPassword, setOpenPopupRecover] = useState(false);

  //Snackbars y dialogos
  const [openAlertSave, setOpenAlertSave] = useState(false);
  const [openAlertInact, setOpenAlertInact] = useState(false);
  const [openAlertErrorInact, setOpenErrorInact] = useState(false);
  const [openAlertDefault, setOpenDefault] = useState(false);
  const [openAlertErrorEditAcc, setOpenErrorEditAcc] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openAlertSaveAccount, setOpenAlertSaveAccount] = useState(false);
  const [openAlertAdminAccess, setOpenAlertAdminAccess] = useState(false);

  //Configuración botones popup
  const [isDisable, setIsDisable] = useState(true);
  const [openDeviceInfo, setOpenDeviceInfo] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  //Organizaciones
  const [organizations, setOrganizations] = useState([]);

  //Información a actualizar
  const [newDefaultAccount, setNewDefaultAccount] = useState({});
  const [infoUpdateAccount, setInfoUpdateAccount] = useState([]);
  const [infoUserToAccount, setInfoUserToAccount] = useState([]);

  //paginacion
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState([]);

  //Filtro por estado 
  const [accountFilter, setAccountFilter] = useState('all');

  //Barra de busqueda
  const [searchTerm, setSearchTerm] = useState('');


  //Seleccionar todas las cuentas
  const [selectAll, setSelectAll] = useState(false);

  const[errorMessageAlert, setErrorMessageAlert] = useState('');


  useEffect(() => {
    if (selectAll) {
      selectAllAccounts();
    } else {
      deselectAllAccounts();
    }
  }, [selectAll]);


  useEffect(() => {
    const title = document.getElementById('title');
    title ? (title.innerHTML = 'Editar usuario') : null;


    const fetchUserData = async () => {
      await getUserToChange(uid);
      const orgs = await getAllOrganizations();
      setOrganizations(orgs);
      setLoading(true);

    };

    fetchUserData();
    getRoles();


    return () => {
      setNewDefaultAccount({});
      setInfoUpdateAccount([]);
      setInfoUserToAccount([]);
      setOpenDeviceInfo(false);
      setAnchorEl(null);
    };
  }, []);

  const getRoles = async () => {
    try {
      const roles = await firebase.getAllRolesAdmin();
      setRoles(roles.map(role => ({ label: role.name, value: role.id })));


    } catch (error) {
      return error;
    }
  };
  

  useEffect(() => {
    if (user.cuentas) {
      const accounts = userAccountsFormat();
      setRows(paginate(accounts, page, rowsPerPage).map((item) => item));
    }
  }, [user, page, rowsPerPage, accountFilter, searchTerm]);


  //manejar el cambio en searchTerm
  useEffect(() => {
    if (searchTerm) {
      setPage(0);
    }
  }, [searchTerm]);



  const updateAllUser = async () => {
    if (Object.keys(newDefaultAccount).length !== 0) {
      await firebase
        .changeDefaultAccount(newDefaultAccount)
        .then(function (result) {
          setOpenPopupAccountSelected(false);
          setNewDefaultAccount({});
          if (result === undefined || null) {
            setOpenDefault(!openAlertDefault);
          }
        })
        .catch(function (error) {
          // Mostrar al usuario que hubo un error
          // volver a la cuenta anterior
          throw new Error(error);
        });
    }

    if (infoUpdateAccount) {
      infoUpdateAccount.map(async (infoAcc) => {
        await firebase
          .updateUserAdmin(infoAcc)
          .then(function (result) {
            setOpenPopupAccountSelected(false);
            setInfoUpdateAccount([]);
            if (result === undefined || null) {
              setOpenErrorEditAcc(true);
            }
          })
          .catch(function (error) {
            throw new Error(error);
          });
      });
    }

    if (infoUserToAccount) {
      infoUserToAccount.map(async (infoAssAcc) => {
        await firebase
          .addUserToAccount(infoAssAcc.cuenta)
          .then(function (result) {
            if (result === undefined || null) {
              throw new Error("Información incompleta");
            } else {
              setInfoUserToAccount([]);
            }
          })
          .catch(function (error) {
            errorHandler(error);
          });
      });
    }
  };

  //Cambia la cuenta por defecto
  const changeDefaultAccount = async (newDefaultAccount) => {
    //Cambiar el estado de usuario con la cuenta por defecto modificada
    setNewDefaultAccount(newDefaultAccount);
    await setDefaultAccount(newDefaultAccount.accountId);
    setOpenPopupAccountSelected(false);
  };

  //Actualiza la información de la cuenta seleccionada
  const updateAccount = async (infoAccount) => {
    const cuenta = {
      uid: infoAccount.uid,
      perfil: infoAccount.perfilName,
      nombre: infoAccount.nombre,
      admin_cuenta: infoAccount.admin_cuenta,
      base_datos: infoAccount.base_datos,
      nodo_raiz: infoAccount.nodo_raiz,
      accountId: infoAccount.accountId,
      superset_activo: infoAccount.superset_activo,
      superset_url: infoAccount.superset_url,
    };
    let info = [...infoUpdateAccount];
    info.push(cuenta);
    setInfoUpdateAccount(info);
    setOpenPopupAccountSelected(false);
    await setAccountEdited(infoAccount);
  };

  //Asigna al usuario una cuenta
  const addUserToAcc = (info, defaultAccount) => {
    if (
      Object.keys(user.cuentas).find((key) => info.accountId === key) !==
      undefined
    ) {
      updateAccount(info);
      if (defaultAccount) {
        changeDefaultAccount(info);
      }
    } else {
      setUserNewAccount(info, defaultAccount);
      if (defaultAccount) {
        setNewDefaultAccount(info);
      }
    }
    const cuenta = {
      uid: info.uid,
      perfil: info.perfilName,
      nombre: info.nombre,
      admin_cuenta: info.admin_cuenta,
      base_datos: info.base_datos,
      nodo_raiz: info.nodo_raiz,
      accountId: info.accountId,
      admin: info.admin,
      superset_activo: info.superset_activo,
      superset_url: info.superset_url,
    };

    let infor = [...infoUserToAccount];
    infor.push({ cuenta: cuenta, info: info });
    setInfoUserToAccount(infor);
    // Mostrar el AppSnackba
    setOpenAlertSaveAccount(true);
    setOpenPopupAssign(false);
  };

  //Devuelve las cuentas con formato para el componente List
  const userAccountsFormat = () => {
    let usrAc = [];
    let defaultAccountData = null;
    let isFiltered = false;

    if (user.cuentas !== undefined) {
      Object.keys(user.cuentas).map((key, index) => {
        const accountData = {
          headerText: user.cuentas[key].nombre,
          bodyText: [
            "Estado",
            user.cuentas[key].perfil !== "inactivo" ? "activo" : "inactivo",
          ],
          id: key.toString(),
          idList: key.toString(),
          admin_cuenta: user.cuentas[key].admin_cuenta
            ? user.cuentas[key].admin_cuenta.toString()
            : "",
          perfil: user.cuentas[key].perfil,
          base_datos: user.cuentas[key].base_datos,
          nodo_raiz: user.cuentas[key].nodo_raiz
            ? user.cuentas[key].nodo_raiz
            : null,
          superset_activo: user.cuentas[key].superset_activo,
          superset_url: user.cuentas[key].superset_url,
        };

        //Guarda el valor de la cuenta por defecto
        if (key === user.defaultAccount) {
          defaultAccountData = accountData;
        }
        usrAc.push(accountData);
      });

      //Filtro de busqueda 
      if (searchTerm) {
        const dataFiltered = dataFilteredFunction(usrAc, searchTerm, 'headerText');
        usrAc = Object.keys(dataFiltered).map((el) => {
          const data = { ...dataFiltered[el] };
          return data;
        });
        isFiltered = true;
      }


      //filtro
      if (accountFilter === "active") {
        usrAc = usrAc.filter((account) => account.bodyText[1] === "activo");
        isFiltered = true;
      } else if (accountFilter === "inactive") {
        usrAc = usrAc.filter((account) => account.bodyText[1] === "inactivo");
        isFiltered = true;
      }

      // Ordena alfabeticamente teniendo en cuenta el estado
      usrAc.sort((a, b) => {
        if (a.bodyText[1] === "activo" && b.bodyText[1] === "inactivo") {
          return -1;
        } else if (a.bodyText[1] === "inactivo" && b.bodyText[1] === "activo") {
          return 1;
        } else {
          return a.headerText.localeCompare(b.headerText);
        }
      });

      // Coloca la cuenta predeterminada al principio de la lista
      if (defaultAccountData && !isFiltered) {
        const defaultIndex = usrAc.findIndex(account => account.id === user.defaultAccount);
        if (defaultIndex > -1) {
          const [defaultAccount] = usrAc.splice(defaultIndex, 1);
          usrAc.unshift(defaultAccount);
        }
      }

      return usrAc;
    }
  };


  const useHelperTextStyles = makeStyles(() => ({
    root: {
      fontSize: "10px",
    },
  }));

  //Maneja el Popup de cuenta seleccionada
  const handlePopupAccountSelected = async (account) => {
    await getAccountWithId(account.id).then((cuenta) => {
      let perfilUsuario = "";
      if (cuenta !== undefined && account) {
        Object.keys(cuenta.perfiles).map((p) => {
          if (p === account.perfil) {
            perfilUsuario = cuenta.perfiles[p].nombre;
          }
        });

        setAccountSelected({
          id: account.id,
          idElement: account.id,
          nombre: account.headerText,
          IdPerfilUsuario: account.perfil,
          nombrePerfilUsuario: perfilUsuario,
          perfiles: cuenta.perfiles,
          admin_cuenta: account.admin_cuenta,
          base_datos: account.base_datos,
          nodo_raiz: account.nodo_raiz ? account.nodo_raiz : null,
          superset_activo: account.superset_activo,
          superset_url: account.superset_url,
        });
        setOpenPopupAccountSelected(true);
        setInact("");
      } else {
        setOpenPopupAccountSelected(false);
      }
    });
  };

  //Maneja el Popup de Asignar una cuenta
  const handlePopupAssign = async () => {
    if (popupOpenAssign === false) {
      const orgs = await getAllOrganizations();
      setOrganizations(orgs);
      setOpenPopupAssign(true);
    } else {
      setOpenPopupAssign(!popupOpenAssign);
    }
  };

  const handleAlert = (action) => {
    switch (action) {
        case "guardar":
            setOpenAlertSave(false);
            break;
        case "asignar":
            setOpenAlertSaveAccount(false);
            break;
        case "inactivar":
            setOpenAlertInact(false);
            break;
        case "editar":
            setOpenErrorEditAcc(false);
            break;
        case "por defecto":
            setOpenDefault(!openAlertDefault);
            break;
        case "error inactivar":
            setOpenErrorInact(false);
            break;
        case "error admin access":
          setOpenAlertAdminAccess(false);
          break;
        default:
            break;
    }
};

  const handleInactivateUser = () => {
    if (accountsInact.length !== 0) {
      setOpenConfirmationModal(!openConfirmationModal);
    }
  };

  //Modal de confirmación para inactivar un usuario
  const handleConfirmationModal = async (action) => {
    if (action === "cancelar") {
      setOpenConfirmationModal(!openConfirmationModal);
    } else if (action === "aceptar") {
      setOpenConfirmationModal(!openConfirmationModal);

      const cuentasFiltradas = Object.keys(user.cuentas)
        .filter((key) => accountsInact.includes(key))
        .reduce((obj, key) => {
          obj[key] = user.cuentas[key];
          return obj;
        }, {});

      const infoAccounts = {
        uid: uid,
        accountsId: cuentasFiltradas,
      };
      setInactiveAccount(infoAccounts);
      setOpenAlertInact(!openAlertInact);
      setInact([]);

      Object.keys(cuentasFiltradas).map(async (key) => {
        const info = {
          uid: uid,
          base_datos: cuentasFiltradas[key].base_datos,
          nodo_raiz: cuentasFiltradas[key].nodo_raiz,
        };
        await firebase
          .inactivateUser(info)
          .then(function (result) {
            if (result === undefined) {
              setInactiveAccount(infoAccounts, "anterior");
              setOpenErrorInact(true);
            } else {
              setOpenAlertInact(!openAlertInact);
              setInact([]);
            }
          })
          .catch(function (error) {
            throw new Error(error);
          });
      });
    }
  };

  //Maneja Popper con la información de dispositivo
  const handleDeviceInfo = (event) => {
    setOpenDeviceInfo(!openDeviceInfo);
    setAnchorEl(event.currentTarget);
  };
  //Maneja Popup de cuenta seleccionada
  const handlePopupCancel = () => {
    setOpenPopupAccountSelected(false);
  };

  //Maneja los checkbox para inactivar una cuenta
  const handleChange = (event) => {
    const { value } = event.target;

    let newOptions = "";

    if (accountsInact.includes(value)) {
      newOptions = accountsInact.filter((opt) => opt !== value);
    } else {
      newOptions = [...accountsInact, value];
    }
    setInact(newOptions);
  };

  //Seleccionar todas las cuentas
  const selectAllAccounts = () => {
    const accounts = userAccountsFormat();
    const allAccountId = accounts.map(account => account.id);
    setInact(allAccountId);
  };
  //Limpiar las cuentas seleccionadas
  const deselectAllAccounts = () => {
    setInact([]);
  };
  //Maneja cambio del estado de un checkbox
  const handleSelectAllChange = (event) => {
    setSelectAll(event.target.checked);
  };


  //Maneja si está deshabilidata la edición
  const handleEditFields = () => {
    setIsDisable(!isDisable);
  };

  //Permite imprimir el error
  const errorHandler = (error, errorInfo) => {
    throw new Error("Loging", error, errorInfo);
  };

  const handlePopupRecoverPassword = () => {
    setOpenPopupRecover(!popupOpenRecoverPassword);
  };

  const handleAccountFilterChange = (e) => {
    setAccountFilter(e.target.value);
    setPage(0);
  };

  //Navegar hacia la ruta user
  function handleUserNavigate() {
    history.push('/users');
  }


  


  return (
    <section className="search">
      {!user ? (
        <h1> </h1>
      ) : (
        loading ? (
          <ErrorBoundary onError={errorHandler}>
            <Formik
              initialValues={{
                name: user.nombre ? user.nombre : "",
                email: user.correo,
                organization: user.organizacion ? user.organizacion : "",
                organization_id: user.organizacion_id ? user.organizacion_id : "",
                version: user.version ? user.version : "",
                device: user.dispositivo ? user.dispositivo : "",
                phone: user.celular ? user.celular : "",
                accounts: {
                  cuentas: userAccountsFormat() ? userAccountsFormat() : [],
                  seleccionadas: {},
                },
                roles_admin: user.roles_admin ? user.roles_admin :[],
                access_admin: user.access_admin ? user.access_admin : false
              }}
              enableReinitialize={true}
              validationSchema={Yup.object({
                name: Yup.string()
                  .max(40, "Debe tener 40 caracteres o menos")
                  .required("Campo requerido"),
                email: Yup.string()
                  .email("Correo electrónico inválido")
                  .required("Campo requerido"),
                organization: Yup.string().required('Este campo es obligatorio').test(
                  'isValidOrganization',
                  'Selecciona una organización válida',
                  value => {
                    const exists = Object.values(organizations).some(org => org.nombre === value);
                    return exists;
                  }
                ),
                version: Yup.string("La versión no es válida"),
                access_admin : Yup.boolean(),
                roles_admin: Yup.array().required().test({
                  name: 'selectedRoles',
                  test: function (value) {
                    if (Array.isArray(value) && value.length > 0 || !this.parent.access_admin) {
                      return true;
                    }
                    return this.createError({ message: 'Debe seleccionar al menos un rol para el usuario' });
                  },
                }),
              })}
              onSubmit={async (values, actions) => {

                const inforNewUser = {
                  uid: uid,
                  name: values.name,
                  email: values.email,
                  organization: values.organization,
                  organization_id: values.organization_id,
                  phone: values.phone,
                };
                updateAllUser();
                await firebase
                  .updateUser(inforNewUser)
                  .then(function (result) {
                    setOpenAlertSave(!openAlertSave);
                    setIsDisable(true);
                  })
                  .catch(function (error) {
                    actions.setSubmitting(false);
                    throw new Error(error);
                  });

                if(values.access_admin || !values.access_admin && user.access_admin){
                  const rolesSelected = values.roles_admin.reduce((acc, { value, label }) => {
                    acc[value] = { 'name': label };
                    return acc;
                  }, {});                
                  
                  await firebase.updateAdminAccessForUser(values.access_admin, rolesSelected, user) 
                  .then(function (result) {
                    if(result.status != 'ok'){
                      setErrorMessageAlert(result.message);
                      setOpenAlertAdminAccess(true);
                    }
                  })
                  .catch(function (error) {
                    throw new Error(error);
                  });
                }
              }}
            >
              {(formik, isSubmitting) => (
                <Form onSubmit={formik.handleSubmit}>
                  <TextField
                    id="name"
                    name="name"
                    type="text"
                    fullWidth
                    label="Nombre"
                    autoComplete="off"
                    disabled={isDisable}
                    value={formik.values.name}
                    error={formik.touched.name && !!formik.errors.name}
                    helperText={formik.touched.name && formik.errors.name}
                    onChange={async (e) => {
                      formik.setFieldValue("name", e.target.value);
                      let us = { ...user };
                      us.nombre = e.target.value;
                      await setUser(us);
                    }}
                  />

                  <TextField
                    id="email"
                    name="email"
                    type="email"
                    fullWidth
                    label="Correo electrónico"
                    autoComplete="off"
                    disabled={isDisable}
                    value={formik.values.email}
                    error={formik.touched.email && !!formik.errors.email}
                    helperText={formik.touched.email && formik.errors.email}
                    onChange={async (e) => {
                      formik.setFieldValue("email", e.target.value);
                      let us = { ...user };
                      us.correo = e.target.value;
                      await setUser(us);
                    }}
                    InputLabelProps={{
                      classes: {
                        root: 'MuiInputLabel-shrink',
                      },
                    }}

                  />
                  <p className='MuiInputLabel-shrink title-organization'>Organización</p>
                  <AppAutocomplete
                    name={'organization'}
                    inputValue={formik.values.organization}
                    disabled={isDisable}
                    data={Object.keys(organizations).map((key) => {
                      const org = organizations[key];
                      return {
                        label: org.nombre,
                        value: key
                      };
                    })
                    }
                    showNoOptions={false}
                    onChange={() => { }}
                    onInputChange={(value) => {
                      formik.setFieldValue("organization", value);
                      const orgId = Object.keys(organizations).find(key => organizations[key].nombre === value);
                      formik.setFieldValue("organization_id", orgId);

                    }}

                  />
                  <AppFormError errorMessage={formik.touched.organization && formik.errors.organization} />                  

                  <div className="device-info">
                    <div className='version-logo'>
                      <TextField
                        className="version"
                        name="version"
                        type="text"
                        label="App versión"
                        autoComplete="off"
                        fullWidth
                        disabled={isDisable}
                        value={formik.values.version}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.version && !!formik.errors.version
                        }
                        helperText={
                          formik.touched.version && formik.errors.version
                        }
                        InputLabelProps={{
                          classes: {
                            root: 'MuiInputLabel-shrink',
                          },
                        }}
                      />
                      <AppButton
                        variant="contained"
                        tipo="modal-contained"
                        onClick={handleDeviceInfo}
                      >
                        <PhoneIphoneIcon />
                      </AppButton>
                    </div>
                    <div className='contact-user'>
                      <AppPhone
                        className="phone"
                        name="phone"
                        type="text"
                        label="Celular"
                        value={formik.values.phone}
                        disabled={isDisable}
                        defaultCountryCode="57"
                        onChange={async (e) => {
                          formik.setFieldValue("phone", e);
                          let us = { ...user };
                          us.celular = e;
                          await setUser(us);
                        }}
                      />
                    </div>
                  </div>
                  {!user?.correo?.endsWith("@galapagoagro.co") && change_password &&(
                    <AppButton
                      variant="contained"
                      tipo="modal-gris"
                      disabled={isDisable}
                      onClick={handlePopupRecoverPassword}
                    >
                      Restablecer contraseña
                    </AppButton>
                  )}
                  {admin_access && (
                   <div className="admin-access">
                    <AppSwitch
                      name={"access_admin"}
                      label={"Acceso administrador de Galápp"}
                      labelLeft={"No"}
                      labelRight={"Si"}
                      className={"switch-field"}
                      checked={formik.values.access_admin}
                      disabled={isDisable}
                      value={formik.values.access_admin}
                      onChange={(e) => {
                        formik.setFieldValue("access_admin", e);
                        !e && formik.setFieldValue("roles_admin", []);
                      }}
                    />
                      {formik.values.access_admin && 
                      <>
                      <AppSelectMultiple
                        id='roles_admin'
                        name='roles_admin'
                        className= 'roles'
                        disabled={isDisable}
                        options={roles}
                        errors = {formik.touched.roles_admin && !!formik.errors.roles_admin}
                        errorMessage = {formik.errors.roles_admin}
                        label="Roles"
                        value={formik.values.roles_admin}
                        onChange={(e) => {
                          formik.setFieldValue("roles_admin", e);
                        }}
                        />
                      </>
                      }
                    </div>
              )}
                    
                  <div className="accounts_list">
                    <div className='filters'>
                      <AppSearch
                        categorySearched={searchTerm}
                        setCategorySearched={setSearchTerm}
                        className="appSearch"
                      />

                      <InputLabel id="account-filter-label">Estado:</InputLabel>
                      <Select
                        className='select-status'
                        value={accountFilter}
                        onChange={handleAccountFilterChange}
                        labelId="account-filter-label"
                      >
                        <MenuItem value="all">Todas</MenuItem>
                        <MenuItem value="active">Activas</MenuItem>
                        <MenuItem value="inactive">Inactivas</MenuItem>
                      </Select>
                    </div>

                    <p className='title-accounts'>Cuentas</p>
                    {!isDisable && (
                      <FormControlLabel
                        className='checkbox-account-all'
                        control={
                          <Checkbox
                            checked={selectAll}
                            onChange={handleSelectAllChange}
                            name="selectAll"
                            color="primary"
                          />
                        }
                        label="Seleccionar todas"
                      />
                    )}
                    <AppList
                      key="ListField"
                      className="accounts"
                      name="accounts"
                      inline={true}
                      data={rows}
                      disabled={isDisable}
                      onSelected={handleChange}
                      selected={accountsInact}
                      onClick={handlePopupAccountSelected}
                      create={handlePopupAssign}

                      colorTextData={[
                        user.defaultAccount,
                        "Cuenta por defecto",
                      ]}
                    />

                    {user.cuentas ? <AppPagination
                      results={userAccountsFormat()}
                      page={page}
                      setPage={setPage}
                      rowsPerPage={rowsPerPage}
                      setRowsPerPage={setRowsPerPage}
                    /> : null}
                  </div>

                  {formik.values.device !== "" ? (
                    <>
                      <Popper
                        open={openDeviceInfo}
                        anchorEl={anchorEl}
                        placement={"bottom"}
                        transition
                        style={{ zIndex: 1500 }}
                      >
                        <AppTable
                          title="Información dispositivo"
                          data={formik.values.device}
                        />
                      </Popper>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="button_confirm_container">
                    <div className="buttons_end">
                    <AppEndButtons
                      isSubmitting={isSubmitting}
                      isDisabled={isDisable}
                      shouldShowExtraButton={accountsInact.length !== 0}
                      extraButtonLabel={'Inactivar'}
                      shouldShowEditButton={edit && selectedOptions === ""}
                      handleExtraButton={handleInactivateUser}
                      handleEdit={handleEditFields}
                      handleExit={handleUserNavigate}
                    />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>

          </ErrorBoundary>
        ) : (

          <CircularProgress
            size={50}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              color: '#FFB81E',
              height: '6rem',
              width: '6rem'
            }}
          />
        )
      )
      }

    {/* TODO: Refactorizar esto por algo más reutilizable */}

      <AppSnackbar
        open={openAlertSave}
        onClose={() => handleAlert("guardar")}
        style="success"
      >
        ¡El usuario ha sido actualizado con éxito!
      </AppSnackbar>
      <AppSnackbar
        open={openAlertSaveAccount}
        onClose={() => handleAlert("asignar")}
        style="success"
      >
        ¡Cuenta asignada con éxito!
      </AppSnackbar>

      <AppSnackbar
        open={openAlertInact}
        onClose={() => handleAlert("inactivar")}
        style="success"
      >
        ¡Se ha inactivado el usuario con éxito!
      </AppSnackbar>
      <AppSnackbar
        open={openAlertDefault}
        onClose={() => handleAlert("por defecto")}
        style="warning"
      >
        Error al cambiar cuenta por defecto
      </AppSnackbar>
      <AppSnackbar
        open={openAlertErrorEditAcc}
        onClose={() => handleAlert("editar")}
        style="warning"
      >
        Error al actualizar la cuenta
      </AppSnackbar>
      <AppSnackbar
        open={openAlertErrorInact}
        onClose={() => handleAlert("errorInactivar")}
        style="warning"
      >
        Error al inactivar los usuarios
      </AppSnackbar>
      <AppSnackbar
        open={openAlertAdminAccess}
        onClose={() => handleAlert("error admin access")}
        style="warning"
      >
        {errorMessageAlert}
      </AppSnackbar>

      <Dialog
        open={openConfirmationModal}
        onClose={() => handleConfirmationModal("cancelar")}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Se requiere confirmación"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Esta seguro de que desea inactivar esta cuenta?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <AppButton
            onClick={() => handleConfirmationModal("cancelar")}
            color="primary"
          >
            Cancelar
          </AppButton>
          <AppButton
            onClick={() => handleConfirmationModal("aceptar")}
            color="primary"
            autoFocus
          >
            Aceptar
          </AppButton>
        </DialogActions>
      </Dialog>

      <PopupAssingAccount
        user={{ ...user, uid }}
        open={popupOpenAssign}
        handleClose={handlePopupAssign}
        setOpen={setOpenPopupAssign}
        newUser={false}
        firstAccount={false}
        organizations={organizations}
        changeDefaultAccount={changeDefaultAccount}
        errorHandler={errorHandler}
        getAccountWithId={getAccountWithId}
        addUserToAcc={addUserToAcc}
        errorStyles={useHelperTextStyles}
      />

      {
        accountSelected === undefined || accountSelected === null ? (
          <h1> </h1>
        ) : (
          <PopupAccountSelected
            user={user}
            open={popupOpenAccountSelected}
            handleClose={handlePopupCancel}
            accountSelected={accountSelected}
            disabled={isDisable}
            changeDefaultAccount={changeDefaultAccount}
            updateAccount={updateAccount}
            handlePopupAccountSelected={handlePopupAccountSelected}
            defaultAccount={user.defaultAccount}
          />
        )
      }

      <PopoupRecoverPassword
        open={popupOpenRecoverPassword}
        handleClose={handlePopupRecoverPassword}
        user={user}
      />

    </section >
  );
}

export default AppPopupEditUser;
