export const formatProfiles = (template, profiles) => {
    if (!profiles || !profiles[template] || !profiles[template].perfiles) {
      return [];
    }
  
    return Object.keys(profiles[template].perfiles).map((key) => ({
      value: key,
      label: profiles[template].perfiles[key].nombre,
    }));
  };
  

export const formatOrganizations = (orgs) => {
    return Object.keys(orgs).map((key) => ({
      value: key,
      label: orgs[key].nombre,
    }));
  };
  

  export const formatAccount = (account, accounts, nameOrganization, index) => {
    const {
      name,
      initialUserProfile,
      rootNode,
      accountType,
      template,
     sqlId,
      initialUserEmail,
    } = account;
    const adjustedIndex = index + 1;
    return {
      accountType: accountType,
      name: name,
      headerText: name,
      bodyText: ['perfil', initialUserProfile],
      rootNode: rootNode,
      idElement: adjustedIndex,
      idAccount: account.id,
      id: rootNode,
      organization: nameOrganization,
      template: template,
      sqlId:sqlId,
      initialUserEmail: initialUserEmail,
      initialUserProfile: initialUserProfile,
      forms: [],
      enableGeneric: false,
      enableExistingAccount: false,
    };
  };
  
 