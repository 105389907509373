
const useTablePaginationAndFilter = (data,
  categorySearched, keysToSearch, rowsPerPage, accountState, profileFilter, filterSelect, filterSearch) => {
  const normalizeString = (str) => {
    return str
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  };

  const normalizedCategorySearched = normalizeString(categorySearched);
  let filteredData = data;

  // Filtrar los datos según la categoría buscada
  if (filterSearch) {
    filteredData = data.filter(item => {
      return keysToSearch.some(key => {
        if (item[key]) {
          const normalizedItemValue = normalizeString(item[key]);
          return normalizedItemValue.includes(normalizedCategorySearched);

        }
        return false;
      });
    });
  }
  // Filtra los datos con el select
  if (filterSelect) {
    filteredData = filteredData.filter((user) => {
      const estadoFilter =
        accountState === 'Todos' || user.estado === accountState;
      const perfilFilter =
        profileFilter === 'Todos' || user.perfil === profileFilter;
      return estadoFilter && perfilFilter;
    });

  }
  const totalPages = Math.max(1, Math.ceil(filteredData.length / rowsPerPage));
  return {
    filteredData, totalPages
  };
};

export default useTablePaginationAndFilter;


