import { useState } from "react";
import { useFirebase } from "../../../core/context/firebase-context";


export const useAccountFromTemplate = () => {
  const firebase = useFirebase();

  const [templates, setTemplates] = useState();

  const formatTemplates = (templates) => {
    let temp = [];
    Object.keys(templates.resumen).map((key) => {
      temp.push({
        value: key,
        label: templates.resumen[key].nombre,
      });
    });
    setTemplates({templates: temp, profiles: templates.datos});
  };


  const getTemplatesInfo = async () => {
    await firebase
      .getTemplates()
      .then(function ({data}) {
        formatTemplates(data);
      })
      .catch(function (error) {
        throw new Error(error);
      });
  };


  return {
    ...templates,
    getTemplatesInfo,
  };
};
