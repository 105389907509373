import React, {useEffect, useState} from 'react';

import {Form, Formik, Field} from 'formik';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CodeVerification from './CodeVerification';
import AppErrorMessage from '../../../core/components/ErrorMessage/AppErrorMessage';
import AppButton from '../../../core/components/Button/AppButton';

import '../index.scss';

import {useFirebase} from '../../../core/context/firebase-context';
import {useHistory} from 'react-router-dom';

export default function SelectTypeVerification({resolver}) {
  const history = useHistory();

  const [typeVerification, setTypeVerification] = useState('phone');
  const [appVerifier, setAppVerified] = useState('');
  const [next, setNext] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [verificationId, setVerificationId] = useState('');
  const firebase = useFirebase();

  useEffect(() => {
    const newAppVerifier = new firebase.app.auth.RecaptchaVerifier(
      'sign-in-button',
      {
        size: 'invisible',
        callback: async function (response) {},
      }
    );

    setAppVerified(newAppVerifier);
  }, [typeVerification]);

  //TODO: Sacar esta funcionalidad de enviar código a un hook o función reutilizable y reemplazarla aqui
  const requestPhoneVerification = async (formik) => {
    try {
      const phoneAuthProvider =
        new firebase.app.auth.PhoneAuthProvider();

      const phoneInfoOptions = {
        multiFactorHint: resolver.hints[0],
        session: resolver.session,
      };

      const verificationId = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        appVerifier
      );
      // Almacena el verificationId en el estado o haz algo con él
      setVerificationId(verificationId);
      setNext(true);
      appVerifier.clear();
    } catch (error) {
      // Maneja el error de verificación del número de teléfono
      appVerifier.clear();
      switch (error.code) {
        case 'auth/too-many-requests':
          setErrorMessage('Demasiadas peticiones');
          break;
        default:
          setErrorMessage('Error interno');
          break;
      }
    }
  };

  const handleSubmitType = (values, formik) => {
    setTypeVerification(values.verificationOption);
    if (values.verificationOption === 'phone') {
      requestPhoneVerification(formik);
    }
  };

  return (
    <section className='two-step-authentication'>
      {!next ? (
        <Formik
          initialValues={{verificationOption: 'phone'}}
          onSubmit={(values, formik) => handleSubmitType(values, formik)}
        >
          {({values, handleSubmit, errors, touched}) => {
            return (
              <Form onSubmit={handleSubmit}>
                <div className='legend_mfa'>
                  <h2>Verificación en dos pasos</h2>
                </div>
                <div className='select-type-mfa'>
                  <Field as={RadioGroup} name='verificationOption'>
                    <FormControlLabel
                      value='phone'
                      control={<Radio color='primary' />}
                      label={
                        'Mensaje de texto al teléfono' +
                        ' ' +
                        resolver?.hints[0].phoneNumber
                      }
                      labelPlacement='end'
                    />
                  </Field>
                  {errorMessage && (
                    <AppErrorMessage
                      message={errorMessage}
                      setMessage={setErrorMessage}
                    ></AppErrorMessage>
                  )}{' '}
                  <AppButton
                    variant='contained'
                    color='primary'
                    type='submit'
                    id='sign-in-button'
                  >
                    Continuar
                  </AppButton>
                  <div className='return'>
                    <a onClick={() => history.go(0)}>Regresar</a>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <CodeVerification
          resolver={resolver}
          verifId={verificationId}
          typeVerification={typeVerification}
        />
      )}
    </section>
  );
}
