import React from 'react';
import menuItems from '../../../pages/Home/utils/menuItems.json';
import {AppMenuItem} from './AppMenuItem';
import {useFirebase} from '../../context/firebase-context';

export const AppMenuList = () => {
  const { user } = useFirebase();

  const keysInUserPermissions = Object.keys(user.permissions).filter(
    (key) => menuItems[key] != undefined && user.permissions[key].view
  );
  
  return (
    <div>
      <ul className='side-menu-list'>
        <li>
          {['menu',...keysInUserPermissions].map((menuItem) => (
            <AppMenuItem key={menuItem} {...menuItems[menuItem]} />
          ))}
        </li>
      </ul>
    </div>
  );
};