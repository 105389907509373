import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";


function TableField({title, data, ...props }) {
  const styles = {
   
  };
  
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  return (
    <TableContainer
    component={Paper}
    >
   <Table aria-label="simple table">
      <TableHead>
        <TableRow>
        <TableCell align="center" colSpan={2}>
          <Typography  style={{ fontWeight: 600 }}> {title}</Typography>                    
        </TableCell>
        </TableRow>
      </TableHead>  
        <TableBody>
        {Object.keys(data).map((key,index)=>{
          return(
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" size='small'>
                {key}
              </TableCell>
              <TableCell align="left" size='small'>{data[key]}</TableCell>
            </TableRow>
          );
        })}
            
        </TableBody>
     </Table>
   </TableContainer>
    
  );
}



export default TableField;