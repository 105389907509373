import React, {useState} from 'react';
require('dotenv').config();

import {useFirebase} from '../../../core/context/firebase-context.js';

// Core
import AppPopup from '../../../core/components/Popup/AppPopup.js';
import AppSelect from '../../../core/components/Input/Select/AppSelect.js';
import AppButton from '../../../core/components/Button/AppButton.js';

//Formik
import {Formik, Form} from 'formik';
import * as Yup from 'yup';

import AppSelectMultiple from '../../../core/components/Input/Select/AppSelectMultiple.js';

// Hooks
import useFetchAccountData from '../hooks/useFetchAccountData.tsx';
import useApiCall from '../hooks/useApiCall.tsx';

import './PopupPublicChanges.scss';

const PopupPublicChanges = ({
  handleClose,
  open,
  organizations,
  dbId,
  dataIdAccount,
  handleSnackBar,
}) => {
  const firebase = useFirebase();

  // Custom Hook para manejar errores y exitos de las llamadas a la api
  const {loading, handleApiCall} = useApiCall();

  const [availableAccountsDestination, setAvailableAccountsDestination] =
    useState(dataIdAccount);

  const testDatabase = process.env.REACT_APP_DB_TEST;

  // Función para filtrar las cuentas de origen solo mostrar las de pruebas
  const accountOrigin = dataIdAccount.reduce((result, account) => {
    if (account && account?.value?.includes(testDatabase)) {
      result.push({
        value: account.value,
        label: account.label !== undefined ? account.label : '-',
      });
    }
    return result;
  }, []);

  const {availableForms, getForms} = useFetchAccountData(
    organizations && organizations[dbId]
  );

  const initialValues = {
    accountOrigin: '',
    accountsDestination: [],
    selectForms: [],
  };

  // Validación de campos del formulario
  const validationObject = {
    accountOrigin: Yup.string().required('Cuenta origen requerida'),
    accountsDestination: Yup.array()
      .required()
      .test({
        name: 'accountsDestination',
        test: function (value) {
          if (Array.isArray(value) && value.length > 0) {
            return true;
          }
          return this.createError({
            message: 'Debe seleccionar al menos una cuenta destino',
          });
        },
      }),
    selectForms: Yup.array()
      .required()
      .test({
        name: 'selectForms',
        test: function (value) {
          if (Array.isArray(value) && value.length > 0) {
            return true;
          }
          return this.createError({
            message: 'Debe seleccionar al menos un formulario',
          });
        },
      }),
  };

  const handleFormSubmit = async (values, actions) => {
    const {selectForms, accountsDestination} = values;

    const requestBody = {
      accountOriginId: values.accountOrigin,
      formsToPublish: selectForms.map((form) => form.value),
    };

    const userToken = await firebase.getToken();

    const promises = accountsDestination.map(({id, database}) => {
      const requestPayload = {
        ...requestBody,
        accountDestinationId: id,
        databaseDestination: database,
      };

      return handleApiCall(
        'v1/form/replicate-forms',
        requestPayload,
        userToken
      );
    });

    try {
      const results = await Promise.all(promises);
      await Promise.all(
        results.map(async (response, index) => {
          if (response.error) {
            let errorMessage = 'Error al publicar formularios';

            if (response.error) {
              if (
                Array.isArray(response.error.errors) &&
                response.error.errors[0]?.message
              ) {
                errorMessage = response.error.errors[0].message;
              } else if (
                Array.isArray(response.error) &&
                response.error[0]?.message
              ) {
                errorMessage = response.error[0].message;
              } else if (response.error.message) {
                errorMessage = response.error.message;
              }
            }

            handleSnackBar({
              message: errorMessage,
              style: 'warning',
            });
          } else {
            handleSnackBar({
              message: 'Operación exitosa',
              style: 'success',
            });
            handleClose();
          }
        })
      );
    } catch (error) {
      handleSnackBar({
        message: 'Error al publicar formularios',
        style: 'error',
      });
    }
  };

  return (
    <AppPopup title='Publicar formulario' onClose={handleClose} open={open}>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={Yup.object(validationObject)}
        onSubmit={(values, actions) => {
          handleFormSubmit(values, actions);
        }}
      >
        {(formik, isSubmitting) => (
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit(e);
            }}
          >
            <>
              <div className='container-selec'>
                <AppSelect
                  id='accountOrigin'
                  name='accountOrigin'
                  label='Cuenta origen'
                  options={accountOrigin}
                  value={formik.values.accountOrigin}
                  errors={
                    formik.touched.accountOrigin &&
                    !!formik.errors.accountOrigin
                  }
                  errorMessage={
                    formik.touched.accountOrigin && formik.errors.accountOrigin
                  }
                  onChange={async (newValue) => {
                    const {value} = newValue.target;

                    formik.setFieldValue('accountOrigin', value);

                    const filteredAccounts = dataIdAccount.filter(
                      (account) => !value || account.value !== value
                    );
                    setAvailableAccountsDestination(filteredAccounts);
                    await getForms(value);
                  }}
                  disabled={!!formik.values.accountsDestination?.length}
                />
              </div>

              <AppSelectMultiple
                id='accountsDestination'
                name='accountsDestination'
                options={availableAccountsDestination}
                label='Cuentas destino'
                value={formik.values.accountsDestination}
                errors={
                  formik.touched.accountsDestination &&
                  !!formik.errors.accountsDestination
                }
                errorMessage={
                  formik.touched.accountsDestination &&
                  formik.errors.accountsDestination
                }
                onChange={(e) => {
                  formik.setFieldValue('accountsDestination', e);
                }}
              />

              <AppSelectMultiple
                id='selectForms'
                name='selectForms'
                options={availableForms}
                label='Formularios'
                value={formik.values.selectForms}
                errors={
                  formik.touched.selectForms && !!formik.errors.selectForms
                }
                errorMessage={
                  formik.touched.selectForms && formik.errors.selectForms
                }
                onChange={(e) => {
                  formik.setFieldValue('selectForms', e);
                }}
              />

              <div className='container-btns'>
                <div className='container-headers'>
                  <AppButton
                    variant='contained'
                    tipo='modal-gris'
                    onClick={handleClose}
                  >
                    Cancelar
                  </AppButton>
                </div>
                <div className='container-headers'>
                  <AppButton
                    variant='contained'
                    disabled={isSubmitting}
                    tipo='modal-amarillos'
                    type='submit'
                    loading={loading}
                  >
                    Aceptar
                  </AppButton>
                </div>
              </div>
            </>
          </Form>
        )}
      </Formik>
    </AppPopup>
  );
};

export default PopupPublicChanges;
