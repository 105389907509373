import DateFnsUtils from '@date-io/date-fns';
import convertTime from './convertTime';
import parseISO from 'date-fns/parseISO';
import getTime from 'date-fns/getTime';

const dateFns = new DateFnsUtils();

/**
 * @param {string} date
 */
export function parseDate(date) {
  if (/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(date)) {
    return dateFns.parse(date, 'yyyy-MM-dd', new Date());
  }

  if (/(0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-([12]\d{3})/.test(date)) {
    return dateFns.parse(date, 'dd-MM-yyyy', new Date());
  }

  return null;
}

/**
 * @param {string} time
 */
export function parseTime(time) {
  return dateFns.parse(convertTime(time), 'hh:mm:ss a', new Date());
}

/**
 * @param {string} dateTime
 */
export function parseDateTime(dateTime) {
  const [date, time] = dateTime.split(' ');

  const dateParsed = parseDate(date);

  if (!time) return dateParsed;

  const dateTimeParsed = `${dateFns.format(dateParsed, 'yyyy-MM-dd')} ${time}`;

  return parseISO(dateTimeParsed);
}

/**
 * Normaliza los campos tipo fecha según el formato indicado
 * @param {Object} item
 * @param {string} format
 */
function normalizeDateData(item, format) {
  return Object.keys(item).reduce((acc, current) => {
    const currentItem = item[current];
    const currentDate =
      currentItem && currentItem.split && currentItem.split(' ');

    if (currentDate && dateFns.isValid(parseDate(currentDate[0]))) {
      const dateSplited = currentItem.split(' ');

      const parsedDate = parseDate(dateSplited[0]);

      const formatedDate = dateFns.format(parsedDate, format);

      let date;

      if (dateSplited.length === 2) {
        date = `${formatedDate} ${dateSplited[1]}`;
      } else {
        date = formatedDate;
      }

      return { ...acc, [`${current}`]: date };
    }

    return { ...acc, [`${current}`]: currentItem };
  }, {});
}

function add_initial_zero(number) {
  return number < 10 ? '0' + number : String(number);
}

export function get_current_datetime_DMY () {
  var date = new Date().toLocaleString('en-US', { timeZone: 'America/Bogota' });
  date = new Date(date);
  var month = add_initial_zero(date.getMonth() + 1);
  var day = add_initial_zero(date.getDate());
  var hours = add_initial_zero(date.getHours());
  var minutes = add_initial_zero(date.getMinutes());
  var seconds = add_initial_zero(date.getSeconds());
  return (
    day +
    '-' +
    month +
    '-' +
    date.getFullYear() +
    ' ' +
    hours +
    ':' +
    minutes +
    ':' +
    seconds
  );
}

export function get_current_datetime_YMDHMS() {
  const date = new Date().toLocaleString('en-US', { timeZone: 'America/Bogota' });
  const formattedDate = new Date(date);
  const year = formattedDate.getFullYear();
  const month = add_initial_zero(formattedDate.getMonth() + 1);
  const day = add_initial_zero(formattedDate.getDate());
  const hours = add_initial_zero(formattedDate.getHours());
  const minutes = add_initial_zero(formattedDate.getMinutes());
  const seconds = add_initial_zero(formattedDate.getSeconds());
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}


export { getTime, DateFnsUtils, normalizeDateData };

export default dateFns;
