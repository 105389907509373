import React from 'react';
import { Link } from 'react-router-dom';

export const AppMenuItem = ({route, icon, title, titleStyle}) => {
  return (
    <Link to={route}>
      <i className={icon}></i>
      {titleStyle == 'h4'? <h4>{title}</h4>:<span>{title}</span>}      
    </Link>
  );
};
