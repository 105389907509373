import { useState } from "react";
import { useFirebase } from '../../../core/context/firebase-context'; 

const useUserManager = () => {
  //Backend
  const firebase = useFirebase();

  //Usuario info
  const [user, setUser] = useState({});

  const [openNewUser, setNewUser] = useState(false);

  //Loading
  const [loading, setLoading] = useState(false);

  // Popup error - Usuario no encontrado
  const [userNotFound, setUserNotFound] = useState(false);

  //Trae todas las organizaciones
  const getAllOrganizations = async () => {
    const organizations = await firebase
      .getOrganizations()
      .then(function (result) {
        return result;
      })
      .catch(function (error) {
        return error;
      });
    return organizations;
  };

  //Consulta una cuenta por id
  const getAccountWithId = async (accountID) => {
    if (accountID) {
      const account = await firebase
        .getAccountById({ accId: accountID })
        .then(function (result) {
          return result.data;
        })
        .catch(function (error) {
          return error;
        });
      return account;
    }
  };

  //Devuelve un objeto con la información del usuario
  const formatUser = async (usr, cuentas) => {
    if (usr === undefined || null) {
      setUser({});
      setUserNotFound(true);
      setLoading(false);
    } else {
      setLoading(false);
      setUser({
        uid: usr.uid,
        nombre: usr.nombre,
        correo: usr.correo,
        perfil: usr.perfil,
        celular: usr.celular,
        version: usr.version,
        organizacion: usr.organizacion,
        organizacion_id: usr.organizacion_id,
        dispositivo: usr.dispositivo,
        defaultAccount: getDefaultAccount(usr),
        cuentas: usr.cuentas,
        access_admin: usr.access_admin,
        roles_admin: usr.roles_admin ? getRoles(usr.roles_admin): []
      });
    }
  };

  //Consulta el usuario por email
  const getUserToChange = async (value) => {
    await firebase
      .findUserByUid(value)
      .then(function (result) {
        formatUser({...result, uid: value});
      })
      .catch(function (error) {
        setUserNotFound(true);
        setLoading(false);
      });
  };
  const getUserByEmail = async (value, callback) => {
    return await firebase
      .findUserByEmail(value)
      .then(function (result) {
        formatUser(result);
        callback && callback(result);
        return result;
      })
      .catch(function (error) {
        setUserNotFound(true);
        setLoading(false);
        return {error:error};
      });
  };
  

  //Maneja el snackbar de Usuario no encontrado
  const handleAlertUser = () => {
    setUserNotFound(!userNotFound);
    setUser([]);
  };

  //Maneja el Popup de Nuevo usuario
  const handleNewUser = () => {
    setNewUser(!openNewUser);
  };

  //Asigna al usuario una cuenta
  const setUserNewAccount = async (info, defaultAccount) => {
    let nUser = { ...user };
    const newAcc = {
      admin_cuenta: info.admin_cuenta,
      base_datos: info.base_datos,
      nodo_raiz: info.nodo_raiz ? info.nodo_raiz : null,
      nombre: info.nombre,
      perfil: info.perfil,
      superset_activo: info.superset_activo,
      superset_url: info.superset_url,
    };
    let cuentas = { ...nUser.cuentas };
    cuentas[info.accountId] = newAcc;
    nUser.cuentas = cuentas;
    if (defaultAccount) {
      nUser.defaultAccount = info.accountId;
      setUser(nUser);
    } else {
      setUser(nUser);
    }
  };

  //Devuelve la cuenta por defecto
  const getDefaultAccount = (infoDefault) => {
    if (infoDefault !== null || infoDefault !== undefined) {
      let defActId = "";
      let nodo_raiz = infoDefault.nodo_raiz ? infoDefault.nodo_raiz : null;
      let base_datos = infoDefault.base_datos;
      if (base_datos && nodo_raiz) {
        defActId = nodo_raiz
          ? base_datos + "@" + nodo_raiz.replace("/", ">")
          : base_datos;
      } else {
        defActId = base_datos;
      }
      return defActId;
    }
  };


  const getRoles = (roles)=>{
    return Object.keys(roles).map(key =>{
      return {value:key, label:roles[key].name};
    });
  };

  //Cambia la cuenta por defecto
  const setDefaultAccount = async (newDefaultAccount) => {
    let nUser = { ...user };
    nUser.defaultAccount = newDefaultAccount;
    setUser(nUser);
  };

  //Cambia el estado de una cuenta a inactivo
  const setInactiveAccount = async (inactiveAccount, anterior) => {
    let nUser = { ...user };
    if (anterior) {
      Object.keys(nUser.cuentas).map((key) => {
        Object.keys(inactiveAccount.accountsId).map((y) => {
          if (key === y) {
            nUser.cuentas[key] = {
              admin_cuenta: inactiveAccount.accountsId[y].admin_cuenta,
              base_datos: inactiveAccount.accountsId[y].base_datos,
              nodo_raiz: inactiveAccount.accountsId[y].nodo_raiz
                ? inactiveAccount.accountsId[y].nodo_raiz
                : null,
              nombre: inactiveAccount.accountsId[y].nombre,
              perfil: inactiveAccount.accountsId[y].perfil,
              superset_activo: inactiveAccount.accountsId[y].superset_activo,
              superset_url: inactiveAccount.accountsId[y].superset_url,
            };
          }
        });
      });
    } else {
      Object.keys(nUser.cuentas).map((key) => {
        Object.keys(inactiveAccount.accountsId).map((y) => {
          if (key === y) {
            nUser.cuentas[key] = {
              admin_cuenta: inactiveAccount.accountsId[y].admin_cuenta,
              base_datos: inactiveAccount.accountsId[y].base_datos,
              nodo_raiz: inactiveAccount.accountsId[y].nodo_raiz
                ? inactiveAccount.accountsId[y].nodo_raiz
                : null,
              nombre: inactiveAccount.accountsId[y].nombre,
              superset_activo: inactiveAccount.accountsId[y].superset_activo,
              superset_url: inactiveAccount.accountsId[y].superset_url,
              perfil: "inactivo",
            };
          }
        });
      });
    }

    setUser(nUser);
  };

  //Cambia los valores de una cuenta
  const setAccountEdited = (infoAccount) => {
    let nUser = { ...user };
    if (infoAccount !== undefined || null) {
      Object.keys(nUser.cuentas).map((key) => {
        if (infoAccount.accountId === key) {
          nUser.cuentas[key] = {
            admin_cuenta: infoAccount.admin_cuenta,
            base_datos: infoAccount.base_datos,
            nodo_raiz: infoAccount.nodo_raiz ? infoAccount.nodo_raiz : null,
            perfil: infoAccount.perfil,
            nombre: infoAccount.nombre,
            superset_activo: infoAccount.superset_activo,
            superset_url: infoAccount.superset_url,
          };
        }
      });
    }

    setUser(nUser);
  };

  return {
    user,
    setUser,
    userNotFound,
    openNewUser,
    setNewUser,
    loading,
    setLoading,
    handleNewUser,
    handleAlertUser,
    getAllOrganizations,
    getAccountWithId,
    getUserToChange,
    setUserNewAccount,
    setDefaultAccount,
    setInactiveAccount,
    setAccountEdited,
    getUserByEmail
  };
};

export default useUserManager;
