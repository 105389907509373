import React from 'react';
import { FirebaseProvider } from './core/context/firebase-context';
import { ThemeProvider } from '@mui/material/styles';
import theme from './core/material-ui/theme';

import Routes from './routes.js';
import './index.css';
import { ToastProvider } from './core/context/toast';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <FirebaseProvider>
        <ToastProvider>
          <Routes />
        </ToastProvider>
      </FirebaseProvider>
    </ThemeProvider>

  );
};

export default App;
