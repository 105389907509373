import React from 'react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/formcontrol';
import InputLabel from '@mui/material/InputLabel';
import FormError from '../../FormError/AppFormError';


import './AppText.scss';

function AppText ({id, name, label, value, type, errors, errorMessage, onChange, disabled,...props}){

return(
    <>
        <FormControl fullWidth>           
        <InputLabel id="text-component">{label}</InputLabel>
        <TextField
            id = {id}
            name ={name}
            type={type?type:'text'}
            fullWidth
            disabled = {disabled?disabled:false}
            error = {errors}
            value={value}
            onChange={(e)=>onChange(e)}
            {...props}
            />
        </FormControl>  
        <FormError errorMessage={errorMessage} />
    </>
);


}

export default AppText;
