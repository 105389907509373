import { useState } from 'react';

interface ApiResponse {
  error?: any;
  message?: string;
}

type HandleApiCall = (
  endPoint: string,
  requestBody: any,
  userToken: string
) => Promise<ApiResponse>;

const useApiCall = (): { loading: boolean; handleApiCall: HandleApiCall } => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleApiCall: HandleApiCall = async (
    endPoint,
    requestBody,
    userToken
  ) => {
    setLoading(true);

    const apiCode = process.env.REACT_APP_BASE_URL;
    const apiUrl = `${apiCode}/${endPoint}`;
    
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        const data: ApiResponse = await response.json();
        throw new Error(data.message);
      }

      const data: ApiResponse = await response.json();
      return data;
      
      
    } catch (error) { 
      return { error: error }; 
    } finally {
      setLoading(false);
    }
  };

  return { loading, handleApiCall };
};

export default useApiCall;
