import React from 'react';
import Collapsible from 'react-collapsible';
import { useFirebase } from '../../context/firebase-context';
import logoAzul from '../../../assets/logo_simbolo_azul.png';

const AppCollapsible = () => {
  const { logout, user } = useFirebase();
  const [userDropdownOpen, setUserDropdown] = React.useState(false);
  const toggleUserMenu = () => setUserDropdown(!userDropdownOpen);

  return (
    <div className='side-menu__header'>
        <div className='side-menu__brand'>
          <img
            src={logoAzul}
            alt='galapp-logo'
          />
        </div>
        <div className='side-menu__user'>
          <div className='side-menu__user-container' onClick={toggleUserMenu}>
            <i className='mdi mdi-account-circle-outline' />
            <div className='side-menu__user-info'>
              <div className='side-menu__user-display'>
                <div>{user.displayName}</div>
                <div>{user.email}</div>
                <br />
              </div>
              <i
                className={
                  userDropdownOpen
                    ? 'mdi mdi-chevron-up'
                    : 'mdi mdi-chevron-down'
                }
              />
            </div>
          </div>

          <Collapsible
            open={userDropdownOpen}
            transitionTime={200}
            triggerDisabled>
            <div className='user-menu'>
              <ul>
                <li>
                  <a onClick={() => logout()}>
                    {' '}
                    <i className='mdi mdi-logout'></i> Cerrar sesión
                  </a>
                </li>
              </ul>
            </div>
          </Collapsible>
        </div>
      </div>
  );
};

export default AppCollapsible;
