import React, { useState } from 'react';
import {useFirebase} from '../../../core/context/firebase-context';
import AppButton from '../../../core/components/Button/AppButton';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import AppErrorMessage from '../../../core/components/ErrorMessage/AppErrorMessage';

import TextField from '@material-ui/core/TextField';

export default function CodeVerification({
  resolver,
  verifId,
  phoneNumber
}) {

  const history = useHistory();

  const [failedVerification, setFailedVerification] = useState(false);
  const [verificationId, setVerificationId] = useState(verifId);

  const [errorMessage, setErrorMessage] = useState('');

  const firebase = useFirebase();

  const handleSubmitVerification = async (values, formik) => {

      try {
        const credential =
          await firebase.app.auth.PhoneAuthProvider.credential(
            verificationId,
            values.codeNumber
          );

        const multiFactorAssertion =
          await firebase.app.auth.PhoneMultiFactorGenerator.assertion(
            credential
          );

        // Completa el inicio de sesión con la verificación en dos pasos
        await resolver.resolveSignIn(multiFactorAssertion);
        history.push('/');
      } catch (error) {
        console.log(error);
        // Manejar errores de verificación en dos pasos
        switch (error.code) {
          case 'auth/invalid-verification-code':
            formik.setErrors({
              codeNumber: 'Código de verificación inválido',
            });
            break;
          case 'auth/missing-verification-code':
            formik.setErrors({
              codeNumber: 'Se requiere código de verificación',
            });
            break;
          case 'auth/code-expired':
            formik.setErrors({
              codeNumber: 'El código ha expirado',
            });
            break;
          case 'auth/credential-already-in-use':
            formik.setErrors({
              codeNumber: 'El código ya ha sido usado',
            });
            break;
          case 'auth/requires-recent-login':
            formik.setErrors({
              codeNumber: 'Se requiere un inicio de sesión reciente',
            });
            break;
          case 'auth/too-may-requests':
            formik.setErrors({
              codeNumber: 'Se ha excedido el número de intentos posibles',
            });
            break;
          default:
            formik.setErrors({
              codeNumber: 'Error interno, por favor intentelo más tarde',
            });
            break;
        }
      }
    
  };

  const validationSchema = Yup.object().shape({
    codeNumber: Yup.string()
      .max(100, 'Máximo de 100 caracteres en el nombre')
      .required('Campo requerido'),
  });

  //TODO: Sacar esta funcionalidad de enviar código a un hook o función reutilizable y reemplazarla aqui
  const handleResend = async (formik) => {
    const appVerifier = new firebase.app.auth.RecaptchaVerifier(
      'resend-code',
      {
        size: 'invisible',
        callback: async function (response) {
        },
      }
    );
      

    try {
      if(phoneNumber){
        const multiFactorSession = await firebase.user.multiFactor.getSession();

        // Specify the phone number and pass the MFA session.
        const phoneInfoOptions = {
          phoneNumber: phoneNumber,
          session: multiFactorSession,
        };
        const phoneAuthProvider =
          new firebase.app.auth.PhoneAuthProvider();
        // Send SMS verification code.
        const verificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          appVerifier
        );

        setVerificationId(verificationId);
        appVerifier.clear();
      }else{
        const phoneAuthProvider =
          new firebase.app.auth.PhoneAuthProvider();
  
        const phoneInfoOptions = {
          multiFactorHint: resolver.hints[0],
          session: resolver.session,
        };
  
        const verificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          appVerifier
        );
  
        // Almacena el verificationId en el estado o haz algo con él
        setVerificationId(verificationId);
        appVerifier.clear();

      }

    } catch (error) {
      // Maneja el error de verificación del número de teléfono
      appVerifier.clear();
      switch (error.code) {
        case 'auth/too-may-requests':
          formik.setErrors({
            verificationOption: 'Se ha excedido el número de intentos posibles',
          });
          break;
        default:
          formik.setErrors({
            codeNumber: 'Error interno, por favor intentelo más tarde',
          });
          break;
      }
    }
  };

  return (
    <div className="two-step-authentication">
      <Formik
        initialValues={{ codeNumber: '' }}
        onSubmit={(values, formik) => handleSubmitVerification(values, formik)}
        validationSchema={validationSchema}
      >
        {(formik) => {
          return (
            <Form>
              <>
                {!failedVerification ? (
                  <>
                    <div className="legend_mfa">
                      <h2>Verificación autenticación en dos pasos</h2>
                    </div>
                    <div className="informative-text_mfa">
                      <p>Ingrese el código enviado a su celular</p>

                      <p>{resolver?.hints[0].phoneNumber}</p>
                    </div>
                    <div className="select-type-mfa">
                      <div className="code-verification">
                        <TextField
                          className="texto"
                          id="codeNumber"
                          name="codeNumber"
                          type="text"
                          onChange={formik.handleChange}
                          value={formik.values.codeNumber}
                          error={
                            formik.touched.codeNumber &&
                            formik.errors.codeNumber
                          }
                          helperText={
                            formik.touched.codeNumber &&
                            formik.errors.codeNumber
                          }
                        />
                        <AppButton
                          variant="contained"
                          color="primary"
                          onClick={(e) => {
                            e.preventDefault();
                            formik.handleSubmit(e);
                          }}
                          type="submit"
                        >
                          Continuar
                        </AppButton>

                        <div id="resend-code" />
                        <div className="resend_code">
                          ¿No recibió el código?
                          <a onClick={() => 
                            handleResend(formik)}
                            >
                             {' '}Volver a enviar
                          </a>
                        </div>
                        {errorMessage && (
                          <AppErrorMessage
                          message={errorMessage}
                          setMessage={setErrorMessage}
                        ></AppErrorMessage>
                        )}{' '}
                      </div>
                    </div>
                  </>
                ) : (
                  <div>
                    <div className="legend_mfa">
                      <h2>Verificación fallida</h2>
                    </div>
                    <AppButton
                      variant="contained"
                      color="primary"
                      onClick={() => setFailedVerification(false)}
                    >
                      Continuar
                    </AppButton>
                  </div>
                )}
              </>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
