import React from 'react';
import { useHistory } from 'react-router-dom';


import './index.scss'; 


const NotAllowed = () => {
  const history = useHistory();

  const onClick = () => {
    history.push('/');
  };

  return (
    <div className="container-not-found">
      <p>Lo sentimos, no tienes los permisos necesarios para acceder a esta página</p>
      <p onClick={onClick} className="back-text">Volver a la página principal</p>
    </div>
  );
};

export default NotAllowed;