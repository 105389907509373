import React from 'react';
import { AppMenuItem } from '../../core/components/SideMenu/AppMenuItem';

const MenuItem = ({route, title, icon }) => {
  return (
    <div className='menu-item'>
        <AppMenuItem
          route={route}
          icon={icon}
          title={title}
          titleStyle = {'h4'}
        />
      </div>
  );
};

export default MenuItem;
