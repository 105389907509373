import { CircularProgress } from '@material-ui/core';
import React from 'react';

export default function IconLoading() {
  return (
    <CircularProgress
      size={50}
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: '#FFB81E',
        height: '6rem',
        width: '6rem'
      }}
    />
  );
}
