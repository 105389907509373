export const getInitialAccountValues = (orgData, accountId) => {
    const resultAccounts = [];

    let initialMasterAccount = false;
    let initialSlaveAccount = "";
  
    Object.keys(orgData).forEach((key) => {
      const account = orgData[key];
      if (account.cuenta_maestra === key && key !== accountId) {
        resultAccounts.push({ label: account.nombre, value: key });
      }
  
      if (key === accountId) {
        if (account.cuenta_maestra === key) {
          initialMasterAccount = true;
        } else {
          initialMasterAccount = false;
          initialSlaveAccount = account.cuenta_maestra;
        }
      }
    });

    return [initialMasterAccount, initialSlaveAccount, resultAccounts];
  };
  