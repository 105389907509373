import React from 'react';
import AppHomeItem from './AppHomeItem';
import './index.scss';
import {useFirebase} from '../../core/context/firebase-context';
import menuItems from './utils/menuItems.json';
import logoBackup from '../../assets/logo_backup.png'; 

function Home() {
  const { user } = useFirebase();

  const title = document.getElementById('title');
  title ? (title.innerHTML = 'Menu') : null;

  const keysInUserPermissions = Object.keys(user.permissions).filter(
    (key) => (menuItems[key] !== undefined && user.permissions[key].view)
  );  

  return (
    <section className='home'>
      {keysInUserPermissions.map((menuItem) => (
            <AppHomeItem key={menuItem} {...menuItems[menuItem]} />
          ))}
    </section>
  );
}
export default Home;