import React from 'react';
import PropTypes from 'prop-types';
import './AppFormError.scss';

function FormError(props) {
  return <div className="form-error">{props.errorMessage}</div>;
}

FormError.propTypes = {
  errorMessage: PropTypes.string,
};

export default FormError;
