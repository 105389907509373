import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';

//Core components
import AppButton from '../../core/components/Button/AppButton';
import AppText from '../../core/components/Input/Text/AppText';
import AppSnackbar from '../../core/components/Snackbar/AppSnackbar';
import AppTableGeneral from '../../core/components/TableGeneral/AppTableGeneral.js';
import AppButtonCreate from '../../core/components/TableGeneral/AppButtonCreate.js';
import AppPagination from '../../core/components/Pagination/AppPagination';
import AppSearch from '../../core/components/Search/AppSearch';
import useTablePaginationAndFilter from './hooks/useTablePaginationAndFilter';

import './styles/index.scss';
//Popups
import PopupNewAccount from './PopupNewAccount';
import PopupPublicChanges from './PublicChanges/PopupPublicChanges';
import PopupPublicRecords from './PublicChanges/PopupPublicRecords';

//Formik
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import './styles/OrganizationSelecStyles.scss';
import { useFirebase } from '../../core/context/firebase-context';
import ButtonPublic from '../../core/components/DropdownButton/ButtonPublic';

//Icons
import PublicIcon from '@mui/icons-material/Public';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import useApiCall from './hooks/useApiCall';



/**
 * Component for displaying a selected organization.
 * @param {object} match - The matched route information.
 */

export default function OrganizationSelected({ match }) {
  const orgId = match.params.id;

  const firebase = useFirebase();
  const { state } = useLocation();
  const {orgs} = !!state && state;
  const history = useHistory();
  const [accounts, setAccounts] = useState(
    orgs && orgs[orgId].cuentas ? orgs[orgId].cuentas : []
  );

  const {create_account, publish_changes} =
    firebase.user.permissions.organizations;

  const [organizations, setOrganizations] = useState([]);

  const [reloadOrganizations, setReloadOrganizations] = useState(false);

  //Filtro
  const [categorySearched, setCategorySearched] = useState('');

  const {handleApiCall} = useApiCall();

  // Definir cols a filtrar
  const keysToSearch = ['nombre', 'id'];

  //Paginación
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [accountSelected, setAccountSelected] = useState({});
  const [openNewAccount, setOpenNewAccount] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupRecords, setOpenPopupRecords] = useState(false);

  //Snackbar
  const [snackbarProps, setSnackBarProps] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const filterSearch = true; //Filtro de busqueda
  const filterSelect = false; //Filtro de select
  const accountState = filterSelect ? filterSelect : undefined;
  const profileFilter = filterSelect ? filterSelect : undefined;
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const title = document.getElementById('title');
    title ? (title.innerHTML = 'Organización seleccionada') : null;
  }, [match]);

  const handleOpenPopup = () => {
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleCloseNewAccount = () => {
    setOpenNewAccount(false);
  };

  const handleOpenRecords = () => {
    setOpenPopupRecords(true);
  };

  const handleClosePopupRecords = () => {
    setOpenPopupRecords(false);
  };

  /**
   * Handles the selection of an account for display.
   * @param {object} acc - The selected account.
   */

  const handlePopupAccountSelected = (acc) => {
    setAccountSelected(acc);
  };

  const handleNewAccount = async () => {
    setOpenNewAccount(true);
  };

  const validationObject = {
    name: Yup.string().required('Campo requerido'),
    id: Yup.string().required('Campo requerido'),
  };

  /**
   * Handles displaying a snackbar message.
   * @param {object} properties - The snackbar properties.
   */

  const handleSnackBar = (properties) => {
    setSnackBarProps(properties);
    setOpenSnackbar(true);
  };

  /**
   * Handles closing the snackbar.
   */
  const handleAlerts = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    getOrganization(match.params.id);
  }, []);

  /**
   * Retrieves organization information from the database.
   * @param {string} idOrg - The organization ID.
   */
  // Id de la organizacion para el router

  const getOrganization = async (idOrg) => {
    await firebase
      .getOrganizationById({id: idOrg})
      .then(function (result) {
        const accountsF = result.data.cuentas ? result.data.cuentas : {};
        setAccounts(accountsF);
        setReloadOrganizations(true);
      })
      .catch(function (error) {
        throw new Error(error);
      });
  };

  useEffect(() => {
    setLoading(true);
    const getCompanies = async () => {
      try {
        await firebase
          .getOrganizations()
          .then(function (result) {
            setOrganizations(result);
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
          });
      } catch (error) {
        throw new Error(error);
      }
    };

    if (reloadOrganizations) {
      getCompanies();
      setReloadOrganizations(false);
    }
  }, [reloadOrganizations]);

  const filteredOrganization = organizations[orgId];
  let dataIdAccount = [];

  if (filteredOrganization && accounts) {
    dataIdAccount = Object.keys(accounts).map((acc) => ({
      label: accounts[acc].nombre,
      value: acc,
      id: acc,
      root_node: accounts[acc].nodo_raiz,
      database: accounts[acc].base_datos,
      cuenta_maestra: accounts[acc].cuenta_maestra
    }));
  }


  // Funcion de paginacion y filtrado para obtener datos filtrados y total de pages
  const {
    filteredData,
    totalPages
  } =
    useTablePaginationAndFilter(dataIdAccount, categorySearched, keysToSearch,
      rowsPerPage, accountState, profileFilter, filterSelect, filterSearch);

  if (page >= totalPages) {
    setPage(totalPages - 1);
  }

  const initialOrganizationValues = {
    name: filteredOrganization?.nombre || '',
    id: filteredOrganization?.id || '',
    status: filteredOrganization?.estado ? filteredOrganization.estado : '',
    accounts: accounts ? accounts : '',
    date: filteredOrganization?.fechaCreacion || '',
    updateDate: filteredOrganization?.fechaModificacion || '',
  };

  const handleRowClick = (accountId) => {
    const urlAccount = `/organizations/${orgId}/accounts/${accountId}`;
    history.replace({
      pathname: urlAccount,
      state: { filteredOrganization, orgs }
      
    });
  };

  if (isLoading) return <div> Loading...</div>;

  const accountsOrderMap = new Map(
    Object.keys(accounts).map((key, index) => [accounts[key].nombre, index])
  );

  const filteredDataTable = filteredData.sort((a, b) => {
    const indexA = accountsOrderMap.get(a.nombre);
    const indexB = accountsOrderMap.get(b.nombre);
    if (indexA === undefined && indexB === undefined) return 0;
    if (indexA === undefined) return 1;
    if (indexB === undefined) return -1;
    return indexA - indexB;
  });

  const addAccountToOrganization = (account) => {
    const { name, rootNode, accountType } = account;
  
    const getDatabase = (type) => {
      switch (type) {
        case 'development':
          return process.env.REACT_APP_DB_DEV;
        case 'testing':
          return process.env.REACT_APP_DB_TEST;
        case 'production':
          return process.env.REACT_APP_DB_PROD;
        default:
          throw new Error(`Unknown account type: ${type}`);
      }
    };
  
    const newAccounts = {};
  
    const database = getDatabase(accountType);

    for (let i = 0; i < Math.min(name.length, rootNode.length); i++) {
      const adjustedNodoRaiz = accountType === 'production' ? `clientes>${rootNode[i]}` : rootNode[i];
      const accountId = `${database}@${adjustedNodoRaiz}`;
  
      newAccounts[accountId] = {
        nombre: name[i],
        nodo_raiz: adjustedNodoRaiz,
        base_datos: database,
      };
    }
    setAccounts({ ...newAccounts, ...accounts });
  };
  

  const handleSubmitNewAccount = async (values) => {
    
      const userToken = firebase.user._lat;
      
      const response = await handleApiCall('v1/account', values, userToken);
      addAccountToOrganization(values);

      if(response.error){
        throw new Error(JSON.stringify(response.error));
      }    
    
  };

  const columnsConfig = [
    { label: 'Nombre', value: 'label' },
    { label: 'Identificador', value: 'id' }
  ];

  return (
    <div className='form_container'>
      <section className='organizations'>
        <Formik
          initialValues={initialOrganizationValues}
          enableReinitialize
          validationSchema={Yup.object(validationObject)}
          onSubmit={() => {}}
        >
          {({handleSubmit, values, handleChange, touched, errors}) => (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(e);
              }}
            >
              <AppText
                id='name'
                name='name'
                type='text'
                label='Nombre'
                disabled
                fullWidth
                value={values.name}
                onChange={handleChange}
                error={touched.name && !!errors.name}
              />
              <AppText
                id='id'
                name='id'
                type='text'
                disabled
                label='id'
                fullWidth
                value={values.id}
                onChange={handleChange}
                error={touched.id && !!errors.id}
              />
              <AppText
                id='status'
                name='status'
                label='Estado'
                type='text'
                value={values.status}
                disabled
              />
              <AppText
                id='date'
                label='Fecha de creación'
                name='date'
                type='datetime'
                fullWidth
                value={values.date}
                disabled
              />
              <AppText
                id='updateDate'
                label='Fecha de modificación'
                name='updateDate'
                type='datetime'
                fullWidth
                value={values.updateDate}
                disabled
              />
              {publish_changes && (
                <ButtonPublic
                  label={'Publicar'}
                  options={[
                    {label: 'Formularios', type: 'FormPopup'},
                    {label: 'Registros', type: 'RecPopup'},
                  ]}
                  onMenuClick={(event, option) => {
                    if (option && option.type === 'FormPopup') {
                      handleOpenPopup();
                    }
                    if (option && option.type === 'RecPopup') {
                      handleOpenRecords();
                    }
                  }}
                  buttonIcon={<PublicIcon />}
                  menuIcon={<KeyboardArrowDownIcon />}
                />
              )}

              <div className='container_header'>
                <AppSearch
                  categorySearched={categorySearched}
                  setCategorySearched={setCategorySearched}
                />
              </div>
              <AppTableGeneral
                title='Cuentas'
                data={filteredDataTable}
                columns={columnsConfig}
                disabled={false}
                page={page}
                rowsPerPage={rowsPerPage}
                onRowClick={handleRowClick}
              />
              <AppPagination
                results={filteredData}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
              />
              <div className='container-Expor'>
                <div className='container-btns'>
                  <AppButton
                    component={Link}
                    to='/organizations'
                    variant='contained'
                    tipo='modal-gris'
                    id='fixedbutton'
                  >
                    Regresar
                  </AppButton>
                  {create_account && (
                    <AppButtonCreate
                      disabled={false}
                      newAccount={true}
                      create={handleNewAccount}
                      onClick={handlePopupAccountSelected}
                    />
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
        <PopupNewAccount
          dbId={orgId}
          organizations={organizations}
          open={openNewAccount}
          handleClose={handleCloseNewAccount}
          accountSelec={accountSelected}
          handleSnackBar={handleSnackBar}
          onSubmit = {handleSubmitNewAccount}
        />
        <PopupPublicChanges
          open={openPopup}
          handleClose={handleClosePopup}
          handleSnackBar={handleSnackBar}
          organizations={organizations}
          dbId={orgId}
          dataIdAccount={dataIdAccount}
        />
        <PopupPublicRecords
          open={openPopupRecords}
          handleClose={handleClosePopupRecords}
          handleSnackBar={handleSnackBar}
          organizations={organizations}
          dbId={orgId}
          dataIdAccount={dataIdAccount}
        />
        <AppSnackbar
          open={openSnackbar}
          onClose={handleAlerts}
          style={snackbarProps.style}
        >
          {snackbarProps.message}
        </AppSnackbar>
      </section>
    </div>
  );
}
