import React from 'react';
import './AppNewPhoto.scss';

import Button from '@material-ui/core/Button';

const defaultPhoto =
  'https://firebasestorage.googleapis.com/v0/b/galapp-6b902.appspot.com/o/assets%2Flogo_simbolo_azul.png?alt=media&token=877e8efb-f24f-404b-9e11-9978b2774bdd';

function NewPhoto(props) {
  const [photoLoading, setPhotoLoading] = React.useState(false);
  const [photoUrl, setPhotoUrl] = React.useState(defaultPhoto);
  let reader = new FileReader();

  const handleFileInput = (event) => {
    let file = event.target.files[0];
    if (!file) return false;

    let valid = file.type.indexOf('image') !== -1;
    if (!valid) throw 'File Type Is Not Supported. Upload an image instead';

    reader.readAsDataURL(file);
    reader.onload = () => {
      proccessImage(reader.result, file);
    };
  };

  const proccessImage = (imgUrl, name) => {
    const quality = 0.8;
    let canvas = document.createElement('canvas');
    let ctx = canvas.getContext('2d');
    let nameFile = name.name;
    props.setNameImg(nameFile);
    let img = new Image();

    img.onload = () => {
      const dimensions = getImageDimensions(img.width, img.height);
      canvas.setAttribute('width', dimensions.width);
      canvas.setAttribute('height', dimensions.height);

      ctx.drawImage(img, 0, 0, dimensions.width, dimensions.height);

      let base64 = canvas.toDataURL('image/png', quality);

      setPhotoUrl(base64);

      const file = dataURLtoFile(base64, nameFile);
      props.setImgPhoto(file);
    };
    img.src = imgUrl;
  };

  // Convert Base64 to Blob
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new window.Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const getImageDimensions = (currentWidth, currentHeight) => {
    const maxWidth = 150;
    const maxHeight = 150;

    let imgRatio = currentWidth / currentHeight;
    let maxRatio = maxWidth / maxHeight;

    if (currentHeight > maxHeight || currentWidth > maxWidth) {
      if (imgRatio < maxRatio) {
        imgRatio = maxHeight / currentHeight;
        currentWidth = imgRatio * currentWidth;
        currentHeight = maxHeight;
      } else if (imgRatio > maxRatio) {
        imgRatio = maxWidth / currentWidth;
        currentHeight = imgRatio * currentHeight;
        currentWidth = maxWidth;
      } else {
        currentHeight = maxHeight;
        currentWidth = maxWidth;
      }
    }

    return { width: parseInt(currentWidth), height: parseInt(currentHeight) };
  };

  return (
    <div className='photo'>
      {photoLoading ? (
        <div> Loading...</div>
      ) : (
        <img src={photoUrl} className='photo-img' alt='Galapp' />
      )}
      <Button variant='contained' htmlFor='archivo'>
        <label htmlFor={'input-photo'}>
          Seleccionar archivo <i className='mdi mdi-folder-upload' />
        </label>
      </Button>

      <input
        type='file'
        id={'input-photo'}
        accept='image/*'
        onChange={handleFileInput}
      />
    </div>
  );
}

export default NewPhoto;
