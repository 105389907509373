import React from 'react';

import AppButton from '../Button/AppButton';
import AppButtonGroup from '../ButtonGroup/AppButtonGroup';

import './AppEndButtons.scss';

export default function AppEndButtons({
  isDisabled,
  shouldShowExtraButton,
  extraButtonLabel,
  handleExtraButton,
  handleEdit,
  handleExit,
  isSubmitting,
  shouldShowEditButton,
}) {
  return (
    <AppButtonGroup
      variant='contained'
      aria-label='outlined primary button group'
    >
      <AppButton
        variant='contained'
        tipo='modal-gris'
        onClick={isDisabled ? handleExit : handleEdit}
      >
        Salir
      </AppButton>

      {isDisabled && shouldShowEditButton && (
        <AppButton
          variant='contained'
          tipo='modal-amarillos'
          onClick={handleEdit}
        >
          Editar
        </AppButton>
      )}

      {!isDisabled && shouldShowExtraButton && (
          <AppButton
            variant='contained'
            tipo='modal-amarillos'
            onClick={handleExtraButton}
          >
            {extraButtonLabel}
          </AppButton>
      )}
      {!isDisabled && (

          <AppButton
            variant='contained'
            type='submit'
            disabled={isSubmitting}
            tipo='modal-amarillos'
          >
            Guardar
          </AppButton>
      )}
    </AppButtonGroup>
  );
}