import { useState } from 'react';
import { useFirebase } from '../../../core/context/firebase-context';


interface Orgs {
  cuentas: { [key: string]: any };
}

interface FormsMenus {
  data: {
    formularios: { [key: string]: any };
    menu: { [key: string]: any };
  };
}

const useFetchAccountData = (organization: Orgs | null) => {
  const firebase = useFirebase();
  const [availableForms, setAvailableForms] = useState<{ value: string; label: string; }[]>([]);

  const getForms = async (optionAccountOrigin: string): Promise<void> => {
    try {
      if (!organization || !organization.cuentas) {
        return;
      }

      const {cuentas} = organization;
      const { base_datos, nodo_raiz } = cuentas[optionAccountOrigin];
        

      const {data}: FormsMenus = await firebase.getFormsMenus({
        database: base_datos,
        rootnode: nodo_raiz,
      });

      const { formularios, menu } = data;

      const avForms = Object.keys(formularios).map(key => ({
        value: key,
        label: menu[key] ? `${menu[key].nombre} (${key})` : key,
        type: formularios[key].tipo
      }));
     
      setAvailableForms(avForms);
    } catch (error) {
      console.error('Error fetching forms and menus:', error);
    }
  };

  return { availableForms, getForms };
};

export default useFetchAccountData;
