import React from 'react';
import { makeStyles, ButtonGroup } from "@material-ui/core";
  
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: 'row',
      justifyContent: 'right',
      marginTop: '20px',
      marginBottom: '20px',
      "& > *:not(:last-child)": {
        marginRight: theme.spacing(2)
       
      }
    }
  }));
  
  const  ButtonGroupComponent = ({ children }) => {
    const classes = useStyles();
    return(
      <ButtonGroup
      aria-label="full width outlined button group"
      className={classes.root}
      >
        {children}
    </ButtonGroup>
    );

  };
  export default  ButtonGroupComponent;