import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#ffb81d',
      light: '#dddddd'
    },
    secondary: {
      main: '#dd4b39'
    }
  },
});

export default theme;