import React from 'react';

function ErrorMessage(props) {
    
  React.useEffect(async () => {
    const errorFile = () => {
      setTimeout(function () {
        props.setMessage('');
      }, 6000);
    };
    errorFile();
  }, []);

  
  
  return <div>
    {props.message ? <div className='form-error'> {props.message}</div> : null}
  </div>;
}

export default ErrorMessage;
