import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import "./index.scss";

import { useFirebase } from "../../core/context/firebase-context";
import AppSideMenu from "../../core/components/SideMenu/AppSideMenu";


import Home from '../Home/index.js';
import Images from '../Images/index.js';
import Tags from '../Tags/index.js';
import Users from '../Users/index.js';
import Organizations from '../Organizations/index.js';
import OrganizationSelected from '../Organizations/OrganizationSelected.js';
import AccountDetails from '../Organizations/AccountDetail/AccountDetails';
import AppPopupEditUser from '../Users/EditUser';
import ExploradorBackup from '../ExploradorBackup';
import AppInfoDetails from '../ExploradorBackup/AppInfoDetails';
import NotAllowed from "../NotAllowed/index.js";


const ConditionalRoute = ({ permission, path, component: Component }) => {
  return permission ? (
    <Route exact path={path} component={Component} />
  ) : (
    <Redirect from={path} to='/not-allowed' />
  );
};

const DashboardRoutes = () => {
  const { user } = useFirebase();

  const {organizations, users, images, tags, backup} = user.permissions;

  return(
  <Switch>
    <Route exact path='/' component={Home} />
    <ConditionalRoute permission={images.view} path='/images' component={Images} />
    <ConditionalRoute permission={tags.view} path='/tags' component={Tags} />
    <ConditionalRoute permission={users.view} path='/users/:uid' component={AppPopupEditUser} />
    <ConditionalRoute permission={users.view} path='/users' component={Users} />
    <ConditionalRoute permission={organizations.view} path='/organizations/:orgId/accounts/:accountId' component={AccountDetails} />
    <ConditionalRoute permission={organizations.view} path='/organizations/:id' component={OrganizationSelected} />
    <ConditionalRoute permission={organizations.view} path='/organizations' component={Organizations} />
    <ConditionalRoute permission={backup.view} path='/explorador-backup/:fb_id/:doc_id' component={AppInfoDetails} />
    <ConditionalRoute permission={backup.view} path='/explorador-backup/:fb_id' component={ExploradorBackup} />
    <ConditionalRoute permission={backup.view} path='/explorador-backup' component={ExploradorBackup} />
    
    <Route path="/not-allowed" component={NotAllowed} />
    <Redirect to="/" />
  </Switch>
  )
  ;
};

function Dashboard(props) {
  const [menuOpen, openMenu] = React.useState(false);

  const onStateChange = (state) => {
    if (!state.isOpen) openMenu(false);
  };

  return (
    <section className="dashboard">
      <AppSideMenu
        isOpen={menuOpen}
        openMenu={openMenu}
        onStateChange={onStateChange}
      />

      <div className="dashboard-content">
        <header className="header hide-for-large">
          <div className="title" id="title">
            {" "}
            Menu{" "}
          </div>
        </header>

        <div className="content">
          <DashboardRoutes />
        </div>
      </div>
    </section>
  );
}

export default Dashboard;
