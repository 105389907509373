import React, {useState} from 'react';

import AppButton from '../Button/AppButton';
import {Menu, MenuItem} from '@material-ui/core';


const ButtonPublic = ({
  label,
  options,
  onMenuClick,
  buttonIcon,
  menuIcon,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (event, option) => {
    onMenuClick && onMenuClick(event, option);
    handleCloseMenu();
  };

  return (
    <div className='root'>
      <div className='container-btn-public'>
        <AppButton
          variant='contained'
          tipo='grey-button'
          id='fixedbutton'
          onClick={handleButtonClick}
        >
          {buttonIcon && (
            <span className='container-icon-world'>{buttonIcon}</span>
          )}
          {label}
          {menuIcon && <span className='container-icon-down'>{menuIcon}</span>}
        </AppButton>
        <Menu
          className='container-menuItem'
          id='format-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          {options.map((option, index) => (
            <MenuItem
              key={index}
              onClick={(event) => handleMenuClick(event, option)}
            >
              <div className='container-list-menu'>{option.label}</div>
            </MenuItem>
          ))}
        </Menu>
      </div>
    </div>
  );
};

export default ButtonPublic;
