export function dataFilteredFunction(data, categorySearched, filter) {
    
  return  Object.keys(data).filter((el) => {
    const dataInfo = data[el] && data[el][filter];
    if(dataInfo) {
    return dataInfo
      .toLowerCase()
      .toString()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .includes(
        categorySearched
          .toString()
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .trim()
      );
  }
  }).reduce((acc, current) => {
    return { ...acc, [current]: data[current] };
  }, {});
}
