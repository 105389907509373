
import { Button } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


function AppListMetadata({ data }) {

    const history = useHistory();
    const returnPage = () => {
        history.goBack();
    };
    return (
        <div className='container-metadata'>
            <ul className='list-metadata'>
                {data.map(({ label, value }) => (
                    <li key={label} className='li-metadata'>
                        <strong>{label}: </strong>{value}
                    </li>
                ))}
            </ul>
            <Button className='button_return' onClick={returnPage}>Regresar</Button>
        </div>
    );
}
export default AppListMetadata;