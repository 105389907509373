import React from 'react';
import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firebase-database';
import 'firebase/firebase-storage';
import 'firebase/firebase-functions';
import firebaseSettings from '../firebase/settings';
import 'firebase/firestore';



const FirebaseContext = React.createContext();
const dbApp = app.initializeApp(firebaseSettings);
const adminDatabase = process.env.REACT_APP_ADMIN_DATABASE || 'galapp-web-administrador';
const Promise = require('promise');

function FirebaseProvider(props) {
  const [user, setUser] = React.useState(null);
  const [isInitialized, setInitialize] = React.useState(false);

  const defaultDb = dbApp.database();  

  const adminDB = dbApp.database(
    `https://${adminDatabase}.firebaseio.com/`
  );

  const rolesRef = adminDB.ref('/roles');
  const usersRef = user ? adminDB.ref(`/users/${user.uid}`) : null;


  const db_firestore = dbApp.firestore();

  const auth = dbApp.auth();
  const storage = dbApp.storage().ref();

  
  
  /**
   * Inicializa la aplicación y configura la base de datos
   * con base al usuario actual.
   * @param {Object} currentUser
  */
 
 const initialize = async (currentUser) => {
   try {
     
     const userSnapshot = await adminDB.ref(`/users/${currentUser.uid}`).once('value');
     const userAdmin = userSnapshot.val();
     
     if(!userAdmin){
       logout();
      }else{
        const userRolesPermissons = await getRolesPermission(userAdmin.roles);
        setUser({...currentUser, ...userAdmin, 'permissions': userRolesPermissons});
      }      
      setInitialize(true);
    } catch (error) {
      console.log(error);
    }
  };
  
  
  if (!isInitialized && !user) {
    auth.onAuthStateChanged(function (user) {
      if (!user) {
        setUser(null);
        
        return setInitialize(true);
      }
      
      if (!isInitialized) {
        initialize(user);
      }
    });
  }



  const getDocumentBackup = async (id) => {
    try {
      const dataCollectionSnapshpt = await db_firestore.collection('backup_galapp').doc(id).get();
      const data = dataCollectionSnapshpt.data();
      
      return data;
    } catch (error) {
      return [];
    }
  };


  // Switch que recibe diferentes proveedores
  const signinwith = async () => {
    const provider = new app.auth.GoogleAuthProvider();
    dbApp.auth().useDeviceLanguage();
    await dbApp.auth().signInWithPopup(provider);
    setInitialize(false);
  };

  const loginWithEmail = async ({ email, password }) => {
    const response = await dbApp.auth().signInWithEmailAndPassword(email, password);
    return response;
  };

  const logout = () => {
    dbApp.auth().signOut();
    setUser(null);
  };

  const getRolesPermission = async (roles) => {
    const permissions = Object.keys(roles).map(async (role) => {
      const roleSnapshot = await adminDB.ref(`/roles/${role}`).once('value');
      return roleSnapshot.val();
    });
  
    const usersPermissions = await Promise.all(permissions);
  
    const combinedPermissions = usersPermissions.reduce((combined, role) => {
      Object.keys(role.permissions).forEach((resource) => {
        if (!combined[resource]) {
          combined[resource] = {};
        }
  
        Object.keys(role.permissions[resource]).forEach((action) => {
          if (combined[resource][action] === undefined || role.permissions[resource][action] === true) {
            combined[resource][action] = role.permissions[resource][action];
          }
        });
      });
  
      return combined;
    }, {});
  
    return combinedPermissions;
  };


  let isSubscribed = false;

  /**
 * @summary Esta función se autoinvoca para actualizar los roles y permisos del usuario.
 * 
 * @description Función autoinvocada para suscribirse a los cambios en los roles y permisos del usuario de la base de datos del administrador,
 * utilizando las referencias rolesRef y usersRef. Cuando se producen cambios en los roles o permisos,
 * las funciones handlePermissionsChange y handleRolesChange se ejecutan para actualizar los datos del usuario.
 * 
 * @returns {Function} Función que puede ser utilizada para dejar de escuchar los cambios y desuscribirse de los eventos.
 * 
 * @function
 * @name updateUserRolesAndPermissions
 */

  const updateUserRolesAndPermissions = (() => {
    if (isSubscribed) {
        return;
    }
    
    const handlePermissionsChange = async (snapshot) => {
        const newPermissions = await getRolesPermission(user.roles);

        if (JSON.stringify(newPermissions) !== JSON.stringify(user.permissions)) {
            setUser((prevUser) => ({ ...prevUser, permissions: newPermissions }));
        }
    };

    const handleRolesChange = async (snapshot) => {
        const userData = snapshot.val();
        const newPermissions = await getRolesPermission(userData.roles);

        if (JSON.stringify(userData.roles) !== JSON.stringify(user.roles)) {
            setUser((prevUser) => ({ ...prevUser, permissions: newPermissions, roles: userData.roles }));
        }
    };

    if (rolesRef && usersRef) {
        rolesRef.on('value', handlePermissionsChange);
        usersRef.on('value', handleRolesChange);
        
        isSubscribed = true; 
        return () => {
            rolesRef.off('value', handlePermissionsChange);
            usersRef.off('value', handleRolesChange);
            isSubscribed = false; 
        };
    }
  })();



  const getAllRolesAdmin = async () => {
    try {
      const roleSnapshot = await adminDB.ref('/roles').once('value');
      const rolesData = roleSnapshot.val();
  
      if (!rolesData) {
        throw new Error('No se encontraron roles en la base de datos');
      }
  
      const rolesArray = Object.entries(rolesData).map(([id, role]) => ({
        id,
        name: role.name,
      }));
 
      return rolesArray;
    } catch (error) {
      console.error('Error al obtener roles desde la base de datos:', error.message);
      throw error; 
    }
  };

  //imagenes storage
  const getStorageImages = async (img) => {
    const url = await storage.child(img).getDownloadURL();
    return url;
  };

  //subir imagen al storage y a la base de datos
  const saveImg = async (data) => {
    //data contiene url, nombrefoto, etiqueta
    await storage.child(data.nombre).put(data.url);

    // Create file metadata to update
    var newMetadata = {
      customMetadata: { tags: data.etiqueta.toString() },
    };

    // Update metadata properties
    await storage.child(data.nombre).updateMetadata(newMetadata);

    const idImg = data.nombre.substr(0, data.nombre.indexOf('.'));
    const imgClear = idImg.replaceAll(' ', '');
    await defaultDb.ref(`/storage/iconos/${imgClear}`).update(data);
  };

  const editImg = async (data) => {
    try {
      let newMetadata = {
        customMetadata: { tags: data.etiqueta.toString() },
      };
      await storage.child(data.nombre).updateMetadata(newMetadata);
    } catch (error) {
      console.log(error);
    }

  };

  // Lista de todas las imagenes
  const getImg = async () => {
    const resultFolder = [];
    await storage.listAll().then(function (res) {
      res.prefixes.forEach(function (folderRef) {
        const infoFolder = {
          name: folderRef.location.path_,
          categori: 'folder',
        };
        resultFolder.push(infoFolder);
      });
      res.items.forEach(async function (itemRef) {
        const path = await getStorageImages(itemRef.location.path_);
        const meta = await storage.child(itemRef.location.path_).getMetadata();
        const metaTags = meta.customMetadata ? meta.customMetadata.tags : '';
        const infoField = {
          name: itemRef.location.path_,
          categori: 'img',
          img: path,
          size: meta.size,
          type: meta.contentType,
          created: meta.timeCreated,
          updated: meta.updated,
          tags: metaTags,
        };
        resultFolder.push(infoField);
      });
    });
    return resultFolder;
  };

  const getStorageIcons = async () => {
    const snapshot = await defaultDb.ref('storage/iconos').once('value');
    const icon = snapshot.val();
    const iconList = Object.keys(icon).map((id) => {
      const tagString = icon[id].etiqueta.toString();
      return {
        id: id,
        nombre: icon[id].nombre,
        etiqueta: tagString,
      };
    });

    return iconList;
  };

  //Traer lista etiquetas
  const allTags = async () => {
    const snapshot = await defaultDb.ref('storage/etiquetas').once('value');
    const tags = snapshot.val();
    const tagsList = Object.keys(tags).map((id) => {
      return {
        id: id,
        nombre: tags[id].nombre,
      };
    });

    return tagsList;
  };
  //Traer nombre de cuentaconst 
  const getAccountName = async (accountKey) => {
    try {
        const snapshot = await defaultDb.ref('cuentas').child(accountKey).once('value');
        const account = snapshot.val();

        if (account) {
            const { nombre } = account;
            return nombre;
        } else {
            return null;
        }
    } catch (error) {
        console.error('Error al obtener el nombre de la cuenta:', error);
        return null;
    }
};



  //Crear etiqueta
  const createTag = async (infoTag) => {
    const newDataKey = defaultDb.ref('storage/etiquetas/').push().key;
    await defaultDb.ref(`/storage/etiquetas/${newDataKey}`).update(infoTag);
  };

  //editar tag 

  const editTag = async (name, id) => {
    const tagsRef = defaultDb.ref('storage/etiquetas');
    try {
      const tagRef = tagsRef.child(id);  
      await tagRef.update({ nombre: name });
    } catch (error) {
      console.error('Error al actualizar el nombre de la etiqueta:', error);
    }
  };

 //Traer usuario por correo
  
  const findUserByEmail = async (email) => {
    try {
      const callableFindUser = app.functions().httpsCallable('find_user');
      const { data } = await callableFindUser(email);

      const usuarioData = {
        uid: data.uid,
        nombre: 'nombre' in data ? data.nombre : data.displayName,
        correo: 'correo' in data ? data.correo : data.email,
        cuentas: 'cuentas' in data ? data.cuentas : [],
        celular: 'celular' in data ? data.celular : '',
        organizacion: 'organizacion' in data ? data.organizacion : '',
        dispositivo: 'device_info' in data ? data.device_info : '',
        version: 'version_name' in data ? data.version_name.toString() : '',
        nodo_raiz: 'nodo_raiz' in data ? data.nodo_raiz.toString() : '',
        base_datos: 'base_datos' in data ? data.base_datos.toString() : '',
      };

      return usuarioData;
    } catch (error) {
      throw new Error(error);
    }
  };
  const findUserByUid = async (uid) => {
    try {
      const callableFindUser = app.functions().httpsCallable('find_user_data_from_uid');
      const { data } = await callableFindUser(uid);

      const userAdminSnapshot = await adminDB.ref(`/users/${uid}`).once('value');
      const userAdminData = userAdminSnapshot.val();

      const usuarioData = {
        uid: data.uid,
        nombre: 'nombre' in data ? data.nombre : data.displayName,
        correo: 'correo' in data ? data.correo : data.email,
        cuentas: 'cuentas' in data ? data.cuentas : [],
        celular: 'celular' in data ? data.celular : '',
        organizacion: 'organizacion' in data ? data.organizacion : '',
        dispositivo: 'device_info' in data ? data.device_info : '',
        version: 'version_name' in data ? data.version_name.toString() : '',
        nodo_raiz: 'nodo_raiz' in data ? data.nodo_raiz.toString() : '',
        base_datos: 'base_datos' in data ? data.base_datos.toString() : '',
        roles_admin: userAdminData ? userAdminData.roles : {},
        access_admin: userAdminData ? true : false
      };

      return usuarioData;
    } catch (error) {
      throw new Error(error);
    }
  };
  //Actualizar usuario por correo
  const updateUser = async (infoNewUser) => {
    try {
      const callableUpdateUser = app
        .functions()
        .httpsCallable('change_user_data_oc');
      const response = await callableUpdateUser(infoNewUser);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  };

  // Consulta nodo cuentas por id

  const getAccountById = async (accountId) => {
    try {
      const callableAccountID = app
        .functions()
        .httpsCallable('get_account_by_id');
      const response = await callableAccountID(accountId);
      return response;
    } catch (error) {

      throw new Error(error);
    }
  };

  const existsAccount = async (accountData) => {
    try {
      const callableAccountID = app.functions().httpsCallable('exists_account');
      const response = await callableAccountID(accountData);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  };

  //Inactivar usuarios

  const inactivateUser = async (infoAccounts) => {
    const callableInactivate = app.functions().httpsCallable('inactivate_user');
    const response = await callableInactivate(infoAccounts);
    return response;
  };

  /**
   * ## update_user_admin_module
   *
   * Modificación del perfil y/o admin_cuenta para un usuario desde el módulo de administración de usuarios de Galapp.
   *
   * @param {object} data Objeto con las variables de la petición.
   * @param {string} data.uid Uid del usuario generado en Firebase Auth.
   * @param {string} data.perfil Nombre del perfil para el usuario. No es el id del perfil sino el nombre.
   * @param {string} data.admin_cuenta Propiedad para indicar si el usuario es administrador de la cuenta o no.
   * @param {string} data.base_datos Nombre de la base de datos de la cuenta activa. No es la url.
   * @param {string} data.nodo_raiz (Opcional) Ruta del nodo raíz dentro de la base de datos de la cuenta activa.
   */

  const updateUserAdmin = async (data) => {
    try {
      const callableUpdateUser = app
        .functions()
        .httpsCallable('update_user_admin_account');
      const response = await callableUpdateUser(data);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  };

  // Traer las organizaciones

  const getOrganizations = async () => {
    const snapshot = await defaultDb.ref('/organizaciones').once('value');
    const organizations = snapshot.val();
    return organizations;
  };

  // Agregar un usuario existente a una cuenta

  const addUserToAccount = async (data) => {
    try {
      const callableAddUserToAccount = app
        .functions()
        .httpsCallable('add_user_to_account');
      const response = await callableAddUserToAccount(data);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  };

  const changeDefaultAccount = async (data) => {
    try {
      const callebleChangeDefaultAccount = app
        .functions()
        .httpsCallable('change_default_account');
      const response = await callebleChangeDefaultAccount(data);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  };



  const createUser = async (data) => {
    try {
      const callebleCreateUser = app
        .functions()
        .httpsCallable('create_user_admin_module');
      const response = await callebleCreateUser(data);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  };

  const createOrganization = async (data) => {
    try {
      const callebleCreateOrganization = app
        .functions()
        .httpsCallable('create_organization');
      const response = await callebleCreateOrganization(data);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * ## updateOrganization
   *
   * Función para actualizar una organización.
   *
   * @param {object} data Objetos con las variables de la petición
   * @param {string} data.dbId Identificador de la base de datos
   * @param {string} data.fechaModificacion Fecha en la que se hizo la actualización de la Organización
   * @param {Array}  data.cuentas Arreglo con las cuentas de la organización
   * @returns
   */

  const updateOrganization = async (data) => {
    try {
      const callebleUpdateOrganization = app
        .functions()
        .httpsCallable('update_organization');
      const response = await callebleUpdateOrganization(data);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  };

  const getFormsMenus = async (data) => {
    try {
      const callableGetFormsMenus = app
        .functions()
        .httpsCallable('get_forms');
      const response = await callableGetFormsMenus(data);
      return response;
    } catch (error) {
      console.error(error);
    }
  };
  


  const addConfig = async (data) => {
    try {
      const callebleCreateOrganization = app
        .functions()
        .httpsCallable('add_configuration');
      const response = await callebleCreateOrganization(data);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  };

  const getOrganizationById = async (data) => {
    try {
      const callableGetOrganization = app
        .functions()
        .httpsCallable('get_organization_by_id');
      const response = await callableGetOrganization(data);
      return response;
    } catch (error) {
      console.error(error);
    }
  };


  const getTemplates = async (data) => {
    try {
      const callableGetOrganization = app
        .functions()
        .httpsCallable('get_templates_organizations_module');
      const response = await callableGetOrganization(data);
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const sendEmailResetPassword = async (data) => {
    const callableResetPassword = app.functions().httpsCallable('reset_password_admin');
    const response = await callableResetPassword(data);
    return response;

  };


  const callableFunction = async (nameFunction, data) => {
    const callable = app.functions().httpsCallable(nameFunction);
    const response = await callable(data);
    return response;
  };

    /**
   * ## updateAdminAccessForUser
   *
   * Función para actualizar acceso del usuario a la base de datos del adminstrador
   *
   * @param {object} data Objetos con las variables de la petición
   * @returns
   */

    const updateAdminAccessForUser = async (userAccess, rolesSelected, user) => {  
        const userSnapshot = await adminDB.ref(`/users/${user.uid}`).once('value');
        const userAdmin = userSnapshot.val();
    
        let callableFunctionName;
        let actionName;

        const data = {
          fb_id: user.uid,
          roles: rolesSelected
        };
    
        switch (true) {
          case !userAdmin:
            callableFunctionName = 'create_user_admin';
            actionName = 'crear';
            break;
    
          case userAccess:
            callableFunctionName = 'edit_user_admin';
            actionName = 'editar';
            break;
    
          default:
            callableFunctionName = 'delete_user_admin';
            actionName = 'eliminar';
            delete data.roles;
            break;
        }
        const response = await callableFunction(callableFunctionName, data);
        return response.data.status != 'ok' ? {"status":"error", "message":`Error al ${actionName} usuario en el Administrador de Galápp`} : response.data; 

    };

  const restoreRecord = async (record) => {
    const callableRecordRestore = app.functions().httpsCallable('record_restore');
    const payload = {log: record};
    const response = await callableRecordRestore(payload);
    return response;
  };
  
  const getToken = async () => {
    const token = await dbApp
      .auth()
      .currentUser.getIdToken()
      .catch(function (error) {});

      return token;
  };


  if (!isInitialized) return null;

  return (
    <FirebaseContext.Provider
      value={{
        user,
        logout,
        setUser,
        storage,
        signinwith,
        initialize,
        isInitialized,
        app,
        getImg,
        saveImg,
        editImg,
        getStorageIcons,
        allTags,
        createTag,
        editTag,
        findUserByEmail,
        updateUser,
        getAccountById,
        inactivateUser,
        updateUserAdmin,
        getOrganizations,
        addUserToAccount,
        changeDefaultAccount,
        createUser,
        createOrganization,
        addConfig,
        getOrganizationById,
        existsAccount,
        getTemplates,
        updateOrganization,
        sendEmailResetPassword,
        loginWithEmail,
        findUserByUid,
        getAllRolesAdmin,
        updateAdminAccessForUser,
        getAccountName,
        restoreRecord,
        getFormsMenus,
        getDocumentBackup,
        getToken,
      }}
      {...props}
    />
  );
}

function useFirebase() {
  const context = React.useContext(FirebaseContext);
  if (context === undefined) {
    throw new Error('useFirebase must be used within a FirebaseProvider');
  } else {
    return context;
  }
}

export { FirebaseProvider, useFirebase };
