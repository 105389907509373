import React, { useState, useCallback, useMemo, useContext } from 'react';
import noop from 'lodash/noop';
import ToastMessages from '../components/ToastMessages';
import { nanoid } from '../util/nanoid';

const DEFAULT_DURATION = 5000;
const DEFAULT_POSITION = 'top center';
const DEFAULT_TOAST_TYPE = 'info';

const ToastContext = React.createContext({
  show: noop,
  dismiss: noop,
});

function ToastProvider({ children }) {
  const [toasts, setToasts] = useState(new Map());

  const show = useCallback((message, options = {}) => {
    const { duration, type, position, id } = options;
    const toastId = id || nanoid(10);

    if (process.env.NODE_ENV !== 'production') {
      if (!message) {
        console.warn(
          `The toast will not be displayed because the message is empty. Toast id: ${toastId}`
        );
      }
    }

    setToasts((prevToasts) => {
      const newToasts = new Map(prevToasts);
      newToasts.set(toastId, {
        open: !!message,
        id: toastId,
        message,
        type: type || DEFAULT_TOAST_TYPE,
        position: position || DEFAULT_POSITION,
        duration: duration || DEFAULT_DURATION,
      });
      return newToasts;
    });
    return toastId;
  }, []);

  const dismiss = useCallback((toastId) => {
    if (typeof toastId === 'string') {
      setToasts((prevToasts) => {
        if (prevToasts.delete(toastId)) {
          return new Map(prevToasts);
        }
        return prevToasts;
      });
    } else {
      setToasts(new Map());
    }
  }, []);

  const value = useMemo(() => ({ show, dismiss }), [show, dismiss]);

  return (
    <ToastContext.Provider value={value}>
      {children}
      <ToastMessages toasts={Array.from(toasts.values())} onClose={dismiss} />
    </ToastContext.Provider>
  );
}

function useToast() {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
}

export { ToastProvider, useToast };
