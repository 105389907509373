import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import { useFirebase } from './core/context/firebase-context';


import Login from './pages/Login/index.js';
import Dashboard from './pages/Dashboard/index.js';

const Routes = () => {
  const firebase = useFirebase();

  if (!firebase.user) {
    return (
      <Router>
        <Switch>
          <Route exact path='/login' component={Login} />
          <Redirect to='/login' />
        </Switch>
      </Router>
    );
  }

  return (
    <Router>
      <Switch>
          <Route path="/" component={Dashboard} />
        </Switch>
    </Router>
  );
};

export default Routes;
