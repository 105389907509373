import React from 'react';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import FormLabel from '@material-ui/core/FormLabel';

import './AppSwitch.scss';

const SwitchField = ({ name, label, labelLeft, labelRight, value, onChange, disabled }) => {
  const isChecked = typeof value === 'string' ? value === 'true' : value;

  const handleChange = (event, checked) => {
    onChange(checked);
  };

  return (
    <div className="switch-field">
      {label && <FormLabel>{label}</FormLabel>}
      <div className="switch-container">
        {labelLeft && <span>{labelLeft}</span>}
        <Switch
          name={name}
          checked={isChecked}
          disabled={disabled}
          onChange={handleChange}
        />
        {labelRight && <span>{labelRight}</span>}
      </div>
    </div>
  );
};

SwitchField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelLeft: PropTypes.string,
  labelRight: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

SwitchField.defaultProps = {
  label: '',
  labelLeft: '',
  labelRight: '',
  value: false,
  disabled: false,
};

export default SwitchField;
