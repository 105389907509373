import React from 'react';
import AppCollapsible from './AppCollapsible';
import { AppMenuList } from './AppMenuList'; 

const SideMenu = () => {

  return (
    <div className='side-menu'>
      <div className='side-menu__container'>
        <AppCollapsible />
        <AppMenuList />
      </div>
    </div>
  );
};

export default SideMenu;
